import React, { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "./App.css";

// Apis
import { getGuestToken, getUserProfile } from "./api/authentications";
import { getAllCategories } from "./api/categories";
import { getWishlist } from "./api/wishlist";

// Scroll animation - AOS
import Aos from "aos";
import "aos/dist/aos.css";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LandingPage from "./views/LandingPage";
import Items from "./views/itemPages/Items";
import ItemDetails from "./views/itemPages/ItemDetails";
import ShoppingCart from "./views/CartPages/ShoppingCart";
import MyProfile from "./views/MyProfilePages/MyProfile";
import ResetPassword from "./views/AuthenticationPages/ResetPassword";
import Notification from "./components/Notification";
import Payment from "./views/Orders/Payment";
import SupportChat from "./components/SupportChat/SupportChat";
import VerifyUserAccount from "./views/AuthenticationPages/VerifyUserAccount";
import ScrollToTop from "./components/ScrollToTop";
import CheckInternetConnection from "./components/CheckInternetConnection";
import CookieConsent from "./components/Cookies/CookieConsent";
import Faq from "./views/FaqPage";
import TermsCondition from "./views/TermsConditionPage"
import PrivacyPolicy from "./views/PrivacyPolicyPage";
import CookiePolicy from "./views/CookiePolicyPage";
import ReturnPolicy from "./views/ReturnPolicy";
import About from "./views/AboutPage";
import Sustainability from "./views/SustainabilityPage";
import Contact from "./views/ContactPage";
// import TrackingOrder from "./views/TrackingOrderPage";

// Cookies
import Cookies from "js-cookie";

// Helpers
import { handleFetchUsersShoppingCarts } from "./helpers/handleFetchUsersShoppingCarts";
import FullscreenView from "./components/FullscreenView";
import { getStorePublicDetails } from "./api/settings";

function App() {
  const mediaQuery = window.matchMedia("(max-width: 640px)");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);
  const categories = useSelector((state) => state.categories);
  const notification = useSelector((state) => state.notification);
  const searchState = useSelector((state) => state.searchState);
  const totalUsersCart = useSelector((state) => state.totalUsersCart);
  const isOpenCookieConsent = useSelector((state) => state.isOpenCookieConsent);
  const isLoading = useSelector((state) => state.isLoading);
  const isSuccessVerifyUser = useSelector((state) => state.isSuccessVerifyUser);
  const isSuccessRequestResetPassword = useSelector(
    (state) => state.isSuccessRequestResetPassword
  );
  const isSuccessResetPassword = useSelector(
    (state) => state.isSuccessResetPassword
  );
  const isSuccessRegister = useSelector(
    (state) => state.isSuccessRegister
  )
  const isSuccessResendVerifyUser = useSelector(
    (state) => state.isSuccessResendVerifyUser
  )

  const handleAcceptCookieConsent = () => {
    dispatch({
      type: "SET_SHOW_MODAL_COOKIES_CONSENT",
      isOpenCookieConsent: false,
    });
    dispatch({
      type: "SET_SHOW_POP_UP_BANNER",
      showPopUpBanner: true,
    });
  };

  const token = Cookies.get("ut");

  const handleDetectWindowSize = (mediaQuery) => {
    dispatch({
      type: "SET_IS_MOBILE_SIZE",
      isMobileSize: mediaQuery.matches,
    });
  };

  const setGuestToken = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getGuestToken();
    if (result && result.data) {
      const { gt } = result.data || {};
      dispatch({ type: "SET_GUEST_TOKEN", guestToken: gt });
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleGetAllCategories = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getAllCategories();
    if (result && result.data) {
      dispatch({ type: "SET_CATEGORIES", categories: result.data });
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleGetUsersProfile = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    try {
      const result = await getUserProfile();
      if (result && result.data) {
        dispatch({ type: "SET_USER_DETAIL", userDetail: result.data });
      }
    } catch (err) {
      console.log(err.response, "err <<");
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleFetchUsersWishlist = async () => {
    // dispatch({
    //   type: "SET_IS_LOADING",
    //   isLoading: true,
    // });
    const result = await getWishlist();
    if (result && result.data) {
      const { data } = result || {};
      dispatch({ type: "SET_WISHLIST", wishlist: data });
    }
    // dispatch({
    //   type: "SET_IS_LOADING",
    //   isLoading: false,
    // });
  };

  const handleOnClickStartShoppingBtn = async () => {
    dispatch({
      type: "SET_IS_SUCCESS_VERIFY_USER",
      isSuccessVerifyUser: false,
    });
    navigate("/");
  };

  useEffect(() => {
    if (mediaQuery) {
      handleDetectWindowSize(mediaQuery);
    }
  }, [mediaQuery]);

  useEffect(() => {
    Aos.init();
    handleGetAllCategories();
  }, []);

  useEffect(() => {
    if (userDetail) {
      handleFetchUsersShoppingCarts(searchState, dispatch);
      handleFetchUsersWishlist();
    } else {
      setGuestToken();
    }
  }, [userDetail]);

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    }
  }, [token]);

  useEffect(() => {
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      document
        .querySelector("[name=viewport]")
        .setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
    }
  }, [navigator])

  const fetchStoreDetails = async () => {
    try {
      const result = await getStorePublicDetails();
      if (result && result.data) {
        const { data } = result || {};
        if (data) {
          dispatch({
            type: "SET_STORE_DETAILS",
            storeDetails: data,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStoreDetails()
  }, [])

  return (
    <div className="App">
      <ScrollToTop />
      <Suspense>
        <Navbar propCategories={categories} totalUsersCart={totalUsersCart} />
        {notification && notification.msg && notification.show ? (
          <Notification msg={notification.msg} success={notification.success} />
        ) : null}
        {isLoading ? <FullscreenView flagger={"loading-animation"} /> : null}
        {isSuccessVerifyUser ? (
          <FullscreenView
            flagger={"notification-success-verify-user"}
            handleOnClickStartShoppingBtn={handleOnClickStartShoppingBtn}
          />
        ) : null}
        {isSuccessRequestResetPassword ? (
          <FullscreenView
            flagger={"notification-success-request-reset-password"}
          />
        ) : null}
        {isSuccessResetPassword ? (
          <FullscreenView flagger={"notification-success-reset-password"} />
        ) : null}
        {isSuccessRegister ? (
          <FullscreenView flagger={"notification-success-register-user"} />
        ) : null}
        {isSuccessResendVerifyUser ? (
          <FullscreenView flagger={"notification-success-resend-verify-user"} />
        ) : null}
        <Routes>
          <Route path="/" name="Landing page" element={<LandingPage />} />
          <Route path="/items" name="Items page" element={<Items />} />
          <Route
            path="/items/:itemId"
            name="Item details page"
            element={<ItemDetails />}
          />
          <Route
            path="/payment"
            name="Payment status page"
            element={<Payment />}
          />
          <Route
            path="/cart"
            name="Shopping cart page"
            element={<ShoppingCart />}
          />
          <Route
            path="/my-profile"
            name="My profile page"
            element={<MyProfile />}
          />
          <Route
            path="/wishlist"
            name="Wishlist page"
            element={<MyProfile />}
          />
          <Route
            path="/my-orders"
            name="My orders page"
            element={<MyProfile />}
          />
          <Route
            path="/my-orders/:orderId"
            name="My orders page"
            element={<MyProfile />}
          />
          <Route
            path="/my-address"
            name="My address page"
            element={<MyProfile />}
          />
          <Route
            path="/my-promo-code"
            name="Promo code page"
            element={<MyProfile />}
          />
          <Route
            path="/account/verify/:verifyUserToken"
            name="Verify user"
            element={<VerifyUserAccount />}
          />
          <Route
            path="/reset/password/:userResetPasswordToken"
            name="Reset password"
            element={<ResetPassword />}
          />
          <Route 
            path="/returnpolicy"
            name="Return Policy"
            element={<ReturnPolicy />}
          />
          <Route 
            path="/faq"
            name="FAQs"
            element={<Faq />}
          />
          <Route 
            path="/contact"
            name="Contact Us"
            element={<Contact />}
          />
          <Route 
            path="/about"
            name="About Us"
            element={<About />}
          />
          <Route 
            path="/sustainability"
            name="Sustainability"
            element={<Sustainability />}
          />
          <Route 
            path="/privacypolicy"
            name="Privacy Policy"
            element={<PrivacyPolicy />}
          />
          <Route 
            path="/cookiepolicy"
            name="Cookie Policy"
            element={<CookiePolicy />}
          />
          <Route 
            path="/termsandconditions"
            name="Terms and Conditions"
            element={<TermsCondition />}
          />
          {/* <Route 
            path="/trackingorder"
            name="Tracking Order"
            element={<TrackingOrder />}
          /> */}
        </Routes>
        <Footer categories={categories} />
      </Suspense>
      <CheckInternetConnection />
      {/* <SupportChat /> */}
      {isOpenCookieConsent &&
        window.location.pathname !== "/privacypolicy" &&
        Cookies.get("cookie_consent") !== "true" && (
          <CookieConsent
            handleAcceptCookieConsent={handleAcceptCookieConsent}
          />
        )}
    </div>
  );
}

export default App;
