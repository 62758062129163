import React from "react";

const Sustainability = () => {
  return (
    <div>
      <section
        className="bg-fixed bg-center bg-no-repeat"
        style={{backgroundImage: `url(${'https://hmgroup.com/wp-content/uploads/2024/03/2041_Sustainability_Header-scaled.jpg'})`}}
      >
        <div 
          className="max-w-main pt-24 pb-20 lg:pt-40 lg:pb-40 flex flex-col items-center main-container"
        >
          <h1 className="max-w-lg text-center text-2xl lg:text-5xl leading-normal text-white maplexxon-animation-end">
            Sustainability
          </h1>
        </div>
      </section>
      <section>
        <div className="max-w-main py-8 lg:py-12 flex flex-col">
          <h1 className="max-w-xl text-left text-2xl lg:text-3xl mb-8 lg:mb-12 maplexxon-animation-end">
            Why sustainability is important to us
          </h1>
          <div className="space-y-2 sm:space-y-4 text-left">
            <p className="text-lg leading-normal">For almost 30 years, we’ve been working with sustainability. It’s part of our business idea – to offer our customers the best combination of fashion, quality, price and sustainability – and we believe that sustainable fashion should not be limited to the few.</p>
            <p className="text-lg leading-normal">We’ve made a lot of progress, however there is still much more to do. Across our industry, we need to reduce our impact on the climate, improve our social impact and build a better fashion system. And we need to do this transparently.</p>
            <p className="text-lg leading-normal">In this section of our website, you will find information on our goals and how we are going to reach them, as well as the standards and policies that guide our work.</p>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-main pb-8 lg:pb-12 flex flex-col">
          <div className="p-12 text-left" style={{backgroundColor: '#E1BEA5'}}>
            <h1 className="max-w-xl text-2xl lg:text-3xl mb-8 maplexxon-animation-end">
              Sustainability essentials
            </h1>
            <div className="space-y-2 sm:space-y-4 text-md sm:text-base">
              <p>If you are new to our sustainability work, we recommend you visit these pages to get an overview of our work:</p>
              <ul className="list-disc">
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Read an interview and watch a short video with Leyla Ertur, our Director of Sustainability, where she talks about our approach to sustainability, our biggest challenges and our focus areas.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Discover what others are saying about us on our awards and recognitions page where we list some of the recent reports and rankings that have featured Moonlight Group.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Explore our Sustainability Disclosure, where we set out in detail what we have achieved and learnt during the previous year as well as listing what we will focus on in the next.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="max-w-main pb-8 lg:pb-12 flex flex-col text-left">
          <h1 className="max-w-xl text-2xl lg:text-3xl mb-8 maplexxon-animation-end">
            Our focus areas
          </h1>
          <p className="text-lg leading-normal">If you want to dig a bit deeper into our work, explore our focus areas to discover how we are addressing our impact:</p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 py-4">
            <div>
              <img src="https://hmgroup.com/wp-content/uploads/2022/01/leading_the_change.jpg" />
              <div className="space-y-2 sm:space-y-4 py-8">
                <p className="text-lg leading-normal">Lorem ipsum dolor sit amet</p>
                <ul className="list-disc">
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                </ul>
              </div>
            </div>
            <div>
              <img src="https://hmgroup.com/wp-content/uploads/2022/01/circularity-1024x576.jpg" />
              <div className="space-y-2 sm:space-y-4 py-8">
                <p className="text-lg leading-normal">Lorem ipsum dolor sit amet</p>
                <ul className="list-disc">
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                </ul>
              </div>
            </div>
            <div>
              <img src="https://hmgroup.com/wp-content/uploads/2022/01/fair_and_equal-1024x576.jpg" />
              <div className="space-y-2 sm:space-y-4 py-8">
                <p className="text-lg leading-normal">Lorem ipsum dolor sit amet</p>
                <ul className="list-disc">
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                  <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Pellentesque finibus.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="pb-8 lg:pb-12 flex flex-col w-full h-full bg-slate-100 py-16 lg:py-24 px-8 lg:px-16"
      >
        <h1 className="w-full text-2xl lg:text-3xl mb-2 maplexxon-animation-end text-center">Latest sustainability news</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mt-2 lg:mt-8">
          <div>
            <div>
              <img src="https://hmgroup.com/wp-content/uploads/2024/08/2024_08_26_Global_Framework_Agreement_16X9-1024x576.jpg" className="h-40 lg:h-80 w-full" />
            </div>
            <div className="bg-white p-8 text-left">
              <div className="mb-2">
                <nav class="w-full rounded-md">
                  <ol class="list-reset flex">
                    <li class="text-neutral-400">
                      26 August, 2024
                    </li>
                    <li>
                      <span class="mx-2 text-neutral-400">/</span>
                    </li>
                    <li class="text-neutral-400">Corporate, Sustainability</li>
                  </ol>
                </nav>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate purus finibus enim hendrerit porttitor. Cras non massa condimentum, lacinia.</p>
            </div>
          </div>
          <div>
            <div className="bg-cover">
              <img src="https://hmgroup.com/wp-content/uploads/2024/08/Stories_fashion_innovation_thumbnail.jpg" className="h-40 lg:h-80 w-full" />
            </div>
            <div className="bg-white p-8 text-left">
              <div className="mb-2">
                <nav class="w-full rounded-md">
                  <ol class="list-reset flex">
                    <li class="text-neutral-400">
                      26 August, 2024
                    </li>
                    <li>
                      <span class="mx-2 text-neutral-400">/</span>
                    </li>
                    <li class="text-neutral-400">Corporate, Sustainability, Fashion</li>
                  </ol>
                </nav>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate purus finibus enim hendrerit porttitor. Cras non massa condimentum, lacinia.</p>
            </div>
          </div>
          <div>
            <div>
              <img src="https://hmgroup.com/wp-content/uploads/2024/08/Production_photoshoot_China-03-1024x683.jpg" className="h-40 lg:h-80 w-full" />
            </div>
            <div className="bg-white p-8 text-left">
              <div className="mb-2">
                <nav class="w-full rounded-md">
                  <ol class="list-reset flex">
                    <li class="text-neutral-400">
                      26 August, 2024
                    </li>
                    <li>
                      <span class="mx-2 text-neutral-400">/</span>
                    </li>
                    <li class="text-neutral-400">Sustainability</li>
                  </ol>
                </nav>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate purus finibus enim hendrerit porttitor. Cras non massa condimentum, lacinia.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sustainability;