import Axios from "../../src/helpers/axios";

export const createTransaction = (body) => {
  return Axios.post("/api/ecommerce/v1/transactions/create", body);
};

export const getTransactionByOrderId = (orderId) => {
  return Axios.get(`/api/ecommerce/v1/transactions/order/id/${orderId}`);
};

export const getTrackingOrder = (body) => {
  return Axios.post(`/api/ecommerce/v1/transactions/tracking/order`, body)
}