import React from "react";

const TermsCondition = () => {
  return (
    <section className="main-container">
      <div className="max-w-main py-8 space-y-4 sm:space-y-8">
        <div className="space-y-2 sm:space-y-4">
          <h1 className="text-2xl lg:text-4xl leading-normal font-bold">Terms And Conditions</h1>
          <p className="text-sm sm:text-base">Updated on 24 October 2023</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-sm sm:text-base">Please read the following terms and conditions carefully before using any software, services, or products provided by Maplexxon - PT Cendekia Edukasi Citra Tritunggal. By accessing or using our software, you agree to abide by these terms and conditions.</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">1. Acceptance of Terms</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>By accessing or using our software, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use our software.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">2. License and Use</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p><span className="font-semibold">2.1. License Grant:</span> Maplexxon- PT Cendekia Edukasi Citra Tritunggal grants you a non-exclusive, non-transferable, limited license to use the software in accordance with these terms.</p>
            <p><span className="font-semibold">2.2. Prohibited Actions:</span> You agree not to reverse engineer, modify, or attempt to gain unauthorized access to the software. Any unauthorized use or breach of these terms may result in the termination of your license.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">3. Account Registration and Security</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p><span className="font-semibold">3.1. Account Information:</span> When registering for an account and subscribing to our service, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information.</p>
            <p><span className="font-semibold">3.2. Security:</span> You are responsible for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">4. Privacy</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p><span className="font-semibold">4.1. Data Collection:</span> By using our software, you consent to the collection and use of your data in accordance with our Privacy Policy.</p>
            <p><span className="font-semibold">4.2. Data Security:</span> We take reasonable measures to secure your data, but we cannot guarantee the security of transmitted information. Use the software at your own risk.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">5. Payment and Billing</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p><span className="font-semibold">5.1. Payment Terms:</span> If applicable, payment terms will be outlined in a separate agreement. Failure to pay fees may result in the suspension or termination of your access to the software.</p>
            <p><span className="font-semibold">5.2. Taxes:</span> You are responsible for any applicable taxes related to your use of our software.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">6. Termination</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>We reserve the right to terminate or suspend your access to the software at any time, with or without cause. Upon termination, you must cease all use of the software and destroy any related materials.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">7. Changes to Terms</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>We may update these terms from time to time. It is your responsibility to review these terms periodically. Your continued use of the software after changes constitutes acceptance of the updated terms.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">8. Disclaimer of Warranties</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>The software is provided "as is" without warranties of any kind. We disclaim all warranties, whether express or implied, including but not limited to fitness for a particular purpose.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">9. Limitation of Liability</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>In no event shall Maplexxon- PT Cendekia Edukasi Citra Tritunggal be liable for any indirect, incidental, special, or consequential damages, arising out of or in connection with the use or inability to use the software.</p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p className="text-lg sm:text-xl font-bold">10. Governing Law</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>These terms and conditions shall be governed by and construed in accordance with the laws of Indonesia. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in West Jakarta, Indonesia.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsCondition;