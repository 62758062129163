import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../styles/footer.css";

// Icons
import TwitterIcon from "../assets/footerIcons/TwitterIcon.png";
import FacebookIcon from "../assets/footerIcons/FacebookIcon.png";
import InstagramIcon from "../assets/footerIcons/InstagramIcon.png";
import LinkedInIcon from "../assets/footerIcons/LinkedInIcon.png";
import YoutubeIcon from "../assets/footerIcons/YoutubeIcon.png";
import VisaMastercardIcon from "../assets/footerIcons/VisaMastercardIcon.png";
import GoPayIcon from "../assets/footerIcons/GoPayIcon.png";
import IndomaretIcon from "../assets/footerIcons/IndomaretIcon.png";
import QRISIcon from "../assets/footerIcons/QRISIcon.png";
import ShopeePayIcon from "../assets/footerIcons/ShopeePayIcon.png";

// Helpers
import { handleAttachQuery } from "../helpers/attach-queries";

const Footer = ({ categories }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);
  const storeDetails = useSelector((state) => state.storeDetails);
  const productListPageState = useSelector(
    (state) => state.productListPageState
  );

  const [isAuthenticationPage, setIsAuthenticationPage] = useState(false);

  useEffect(() => {
    if (pathname && pathname.includes("reset-password")) {
      setIsAuthenticationPage(true);
    }
  }, [pathname]);

  const handleOnClickCategoryItem = (category) => {
    let urlQueries;
    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: {
        ...searchState,
        category,
        subCategories: category.sub_categories,
      },
    });
    urlQueries = handleAttachQuery(
      {
        ...searchState,
        category,
        subCategories: category.sub_categories,
      },
      productListPageState
    );
    navigate(`/items?${urlQueries}`);
  };

  if (isAuthenticationPage) {
    return null;
  } else {
    return (
      <div className="footerContainer">
        <div className="footerNavigationContainer">
          <div className="footerNavigationMain">
            <div className="footerNavigationMainList">
              <div className="footerUlNoBullets">
                <div className="footerUlNoBulletsLabel">Customer Care</div>
                {/* <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/trackingorder`)}
                >
                  Track an order
                </div> */}
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/returnpolicy`)}
                >
                  Returns and Exchanges Policy
                </div>
                {/* <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/payment`)}
                >
                  Payment
                </div> */}
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/faq`)}
                >
                  FAQs
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/contact`)}
                >
                  Contact us
                </div>
              </div>
              <div className="footerUlNoBullets">
                <div className="footerUlNoBulletsLabel">About us</div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/about`)}
                >
                  About us
                </div>
                {/* <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/`)}
                >
                  Career
                </div> */}
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/sustainability`)}
                >
                  Sustainability
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/privacypolicy`)}
                >
                  Privacy Policy
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/cookiepolicy`)}
                >
                  Cookie Policy
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/termsandconditions`)}
                >
                  Terms and Conditions
                </div>
              </div>
              <div className="footerUlNoBullets">
                <div className="footerUlNoBulletsLabel">Products</div>
                {categories &&
                  Array.isArray(categories) &&
                  categories.length > 0 &&
                  categories.map((category, index) => {
                    const { id, name } = category;
                    if (index < 5) {
                      return (
                        <div
                          key={id}
                          className="footerUlNoBulletsOptions"
                          onClick={() => handleOnClickCategoryItem(category)}
                        >
                          {name}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() =>
                    navigate(
                      `/items?${handleAttachQuery(
                        searchState,
                        productListPageState
                      )}`
                    )
                  }
                >
                  View all
                </div>
              </div>
              {/* <div className="footerUlNoBullets">
                <div className="footerUlNoBulletsLabel">Others</div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/`)}
                >
                  Club Fitting
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/`)}
                >
                  Consignment
                </div>
                <div
                  className="footerUlNoBulletsOptions"
                  onClick={() => navigate(`/`)}
                >
                  Golf Event and Tours
                </div>
              </div> */}
            </div>
            {!isMobileSize && (
              <div className="footerNavigationSubscribe">
                <div className="footerNavigationSubscribeLabel">
                  Follow us on social media
                </div>
                <div className="footerNavigationSubscribeSocialMedia">
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterIcon} alt="twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={FacebookIcon} alt="facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={InstagramIcon} alt="instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={LinkedInIcon} alt="linkedIn" />
                  </a>
                  <a
                    href="https://www.youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={YoutubeIcon} alt="youtube" />
                  </a>
                </div>
              </div>
            )}
          </div>
          {isMobileSize ? (
            <div className="footerNavigationMain">
              <div className="footerNavigationSubscribe">
                <div className="footerNavigationSubscribeLabel">
                  Follow us on social media
                </div>
                <div className="footerNavigationSubscribeSocialMedia">
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterIcon} alt="twitter" />
                  </a>
                  <a
                    href="https://www.facebook.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={FacebookIcon} alt="facebook" />
                  </a>
                  <a
                    href="https://www.instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={InstagramIcon} alt="instagram" />
                  </a>
                  <a
                    href="https://www.linkedin.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={LinkedInIcon} alt="linkedIn" />
                  </a>
                  <a
                    href="https://www.youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={YoutubeIcon} alt="youtube" />
                  </a>
                </div>
              </div>
              <div className="footerPaymentAccept">
                <div className="footerPaymentAcceptLabel">
                  {storeDetails?.shop_name} accepts
                </div>
                <div className="footerPaymentAcceptIcons">
                  <img
                    className="w-10"
                    src={VisaMastercardIcon}
                    alt="twitter"
                  />
                  <img
                    className="w-10"
                    src={GoPayIcon}
                    alt="GoPay"
                  />
                  <img
                    className="w-10"
                    src={IndomaretIcon}
                    alt="Indomaret"
                  />
                  <img
                    className="w-10"
                    src={QRISIcon}
                    alt="QRIS"
                  />
                  <img
                    className="w-10"
                    src={ShopeePayIcon}
                    alt="Shop"
                  />
                </div>
                <div>
                  More payment methods available
                </div>
              </div>
            </div>
          ) : (
            <div className="footerPaymentAccept">
              <div className="footerPaymentAcceptLabel">{storeDetails?.shop_name} accepts</div>
              <div className="footerPaymentAcceptIcons">
                <img
                  className="w-20"
                  src={VisaMastercardIcon}
                  alt="visa mastercard"
                />
                <img
                  className="w-20"
                  src={GoPayIcon}
                  alt="GoPay"
                />
                <img
                  className="w-20"
                  src={IndomaretIcon}
                  alt="Indomaret"
                />
                <img
                  className="w-20"
                  src={QRISIcon}
                  alt="QRIS"
                />
                <img
                  className="w-20"
                  src={ShopeePayIcon}
                  alt="Shop"
                />
              </div>
              <div className="text-sm mt-2">
                More payment methods available
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default Footer;
