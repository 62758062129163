import React, { useState } from "react";

// Image
import ArrowLeftIcon from '../assets/itemImagesAndIcons/ArrowRightIcon.png'

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [subAccordionOpen, setSubAccordionOpen] = useState(false);

  const [activeSubAccordion, setActiveSubAccordion] = useState(null);

  const handleActiveSubAccordion = (key) => {
    if (key === activeSubAccordion) {
      setSubAccordionOpen(false);
      setActiveSubAccordion(null);
    } else {
      setSubAccordionOpen(true);
      setActiveSubAccordion(key);
    }
  }

  const renderHtml = (htmlObject) => { return <div dangerouslySetInnerHTML={htmlObject} /> };

  return (
    <div className="p-4 bg-white rounded-lg border-2">
      <div className="py-2">
        <button onClick={() => setAccordionOpen(!accordionOpen)} className="flex items-center w-full">
          <img
            src={ArrowLeftIcon}
            className={`${accordionOpen ? '-rotate-90' : 'rotate-90'} mr-4 ms-2`}
            style={{ width: '8px', height: 'auto' }}
          />
          <span>{title}</span>
        </button>
        <div className={`grid overflow-hidden transition-all duration-300 ease-in-out text-sm ${accordionOpen ? `grid-rows-[1fr] opacity-100 py-3` : `grid-rows-[0fr] opacity-0`
          }`}>
          <div className="overflow-hidden">
            {
              answer && Array.isArray(answer) && answer.length > 0 ? (
                answer.map((value) => {
                  const { headerSubList, content } = value
                  return (
                    <div className="lg:p-4 mb-3 bg-white rounded-lg">
                      <div className="py-2">
                        <button onClick={() => handleActiveSubAccordion(headerSubList)} className="flex justify-between items-center w-full">
                          <span className="font-semibold text-md lg:text-lg text-left w-64 lg:w-full">{headerSubList}</span>
                          <img
                            src={ArrowLeftIcon}
                            className={`${subAccordionOpen && activeSubAccordion === headerSubList ? '-rotate-90' : 'rotate-90'} mr-2`}
                            style={{ width: '8px', height: 'auto' }}
                          />
                        </button>
                        <div className={`grid overflow-hidden transition-all duration-300 ease-in-out text-base ${subAccordionOpen && activeSubAccordion === headerSubList ? `grid-rows-[1fr] opacity-100 py-3` : `grid-rows-[0fr] opacity-0`
                          }`}>
                          <div className="overflow-hidden leading-7 tracking-wide" style={{ whiteSpace: 'pre-wrap' }}>
                            {
                              renderHtml({ __html: content })
                            }
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  )
                })
              ) : { answer }
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion;