import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// Moment.js
import moment from "moment";

// // html to pdf converter
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";

// Icons and Images
import SearchIcon from "../../../assets/navbarIcons/SearchIcon.png";
import BackToPreviousPageIcon from "../../../assets/myProfileIcons/BackToPreviousPageIcon.png";
import DownloadIcon from "../../../assets/myProfileIcons/DownloadIcon.png";
import EmptyWishlistIcon from "../../../assets/myProfileIcons/EmptyWishlistIcon.png";

// Components
import { InputText } from "../../../components/Inputs";
import SearchResult from "../../../components/SearchResult";

// Apis
import { getUsersOrders } from "../../../api/orders";
import { getDetailCity, getDetailSubDistrict } from "../../../api/countries";

// Helpers
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { getTransactionByOrderId } from "../../../api/transactions";
import { formatPrice } from "../../../helpers/format-price";
import Pdf from "../../OrderDetailPdf";

const MyOrdersTab = (props) => {
  const { navigate, isMobileSize, dispatch } = props;

  const searchState = useSelector((state) => state.searchState);

  const { orderId } = useParams();
  const [orders, setOrders] = useState([]);
  const [orderDetail, setOrderDetail] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState([]);
  // const [orderDetailViewForPDF, setOrderDetailViewForPDF] = useState(false);

  const orderMetadata = orderDetail?.products?.[0]?.metadata ? JSON.parse(orderDetail?.products?.[0]?.metadata) : {}

  const fetchUserLoginOrders = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getUsersOrders();
    if (result && result.data) {
      const { data } = result || {};
      setOrders(data);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  useEffect(() => {
    fetchUserLoginOrders();
  }, []);

  const handleSetOrderDetail = async (orderDetail) => {
    let finalData = orderDetail;
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    if (finalData && finalData.shipping_address) {
      if (finalData.shipping_address.city_id) {
        const foundCity = await getDetailCity(
          finalData.shipping_address.city_id
        );
        if (foundCity && foundCity.data) {
          const { data: { city_name } = {} } = foundCity;
          finalData = {
            ...finalData,
            shipping_address: {
              ...finalData.shipping_address,
              city_name,
            },
          };
        }
      }
      if (finalData.shipping_address.subdistrict_id) {
        const foundSubDistrict = await getDetailSubDistrict(
          finalData.shipping_address.subdistrict_id
        );
        if (foundSubDistrict && foundSubDistrict.data) {
          const { data: { subdistrict_name } = {} } = foundSubDistrict;
          finalData = {
            ...finalData,
            shipping_address: {
              ...finalData.shipping_address,
              subdistrict_name,
            },
          };
        }
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
    setOrderDetail(finalData);
  };

  const fetchOrderDetail = async (orderId) => {
    const result = await getTransactionByOrderId(orderId);
    if (result && result.data) {
      const { data } = result || {};
      if (data && data.id) {
        handleSetOrderDetail(data);
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    if (orderId) {
      fetchOrderDetail(orderId);
    } else {
      setOrderDetail(null);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  }, [orderId]);

  useEffect(() => {
    if (searchQuery && searchQuery !== "") {
      let finalSearchValue = orders;
      finalSearchValue = finalSearchValue.filter((order) => {
        const { transaction_id, products } = order || {};
        let isThereMatchProductName = false;
        for (let i = 0; i < products.length; i++) {
          const { product_name } = products[i];
          if (product_name.toLowerCase().includes(`${searchQuery}`)) {
            isThereMatchProductName = true;
            break;
          }
        }
        if (
          transaction_id.toLowerCase().includes(`${searchQuery}`) ||
          isThereMatchProductName
        ) {
          return order;
        }
      });
      setSearchValue(finalSearchValue);
    } else {
      setSearchValue([]);
    }
  }, [searchQuery]);

  const handleClickBackToPreviousPage = () => {
    setOrderDetail(null);
    navigate("/my-orders");
  };

  const handleOnClickPrintBtn = (fileUrl) => {
    window.open(fileUrl);
    // setOrderDetailViewForPDF(true);
    // setTimeout(() => {
    //   const element = document.getElementById(
    //     "myOrderDetailContentContainerForPDF"
    //   );
    //   html2canvas(element)
    //     .then((canvas) => {
    //       const imgData = canvas.toDataURL("image/png");
    //       const pdf = new jsPDF();
    //       const imgProps = pdf.getImageProperties(imgData);
    //       const pdfWidth = pdf.internal.pageSize.getWidth();
    //       const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //       pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    //       pdf.save("converted.pdf");
    //     })
    //     .catch((err) => {
    //       console.log(err, "error");
    //     })
    //     .finally(() => {
    //       setOrderDetailViewForPDF(false);
    //     });
    // }, [10]);
  };

  return (
    <div className="tabMainContainer">
      {!orderDetail ? (
        <>
          {orders && Array.isArray(orders) && orders.length > 0 ? (
            <div className="searchOrderContainer">
              <div className="relative flex items-center" style={{ width: isMobileSize ? "100%" : "97.2%" }}>
                <img
                  src={SearchIcon}
                  alt="search-icon"
                  className="absolute w-4 h-4 ml-2"
                />
                <InputText
                  borderRadius={"10px"}
                  width={isMobileSize ? "100%" : "63.75vw"}
                  height={isMobileSize ? "45px" : "5.5vh"}
                  backgroundColor={isMobileSize ? "#FFFFFF" : "#F9F9F9"}
                  placeholder={
                    isMobileSize
                      ? "Search order ID / product name"
                      : "Search for order ID or product name"
                  }
                  border={"none"}
                  fontSize={isMobileSize ? "12px" : "16px"}
                  marginLeft={isMobileSize ? "" : "-1.3em"}
                  paddingLeft={isMobileSize ? "2.75em" : "3.25em"}
                  setter={setSearchQuery}
                  value={searchQuery}
                />
              </div>
            </div>
          ) : null}
          {searchValue &&
            Array.isArray(searchValue) &&
            searchValue.length > 0 ? (
            <div className="searchResultContainer">
              <div className="searchResultContentContainer">
                <div className="searchResultLabel">Search Result</div>
                <div className="searchResultMainContainer">
                  {searchValue.map((order, index) => {
                    const { transaction_id, transaction_date, products } =
                      order || {};

                    let firstProductItem;
                    if (
                      products &&
                      Array.isArray(products) &&
                      products.length > 0
                    ) {
                      firstProductItem = products[0];
                    }
                    let leftItemsCounter = products.length - 1;
                    let isLastIndex =
                      index === searchValue.length - 1 ? true : false;
                    return (
                      <div
                        key={index}
                        onClick={() => navigate(`/my-orders/${transaction_id}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          style={{
                            paddingTop: isMobileSize ? "0.5em" : "1em",
                            paddingBottom: isMobileSize ? "0.5em" : "1em",
                          }}
                        >
                          <div className="eachMyOrderIdAndStatus">
                            <div className="eachMyOrderIdAndDateContainer">
                              <SearchResult
                                text={`ORDER ID: ${transaction_id}`}
                                searchKey={searchQuery}
                                className="eachResultSearchStyle"
                              />
                              <div className="eachMyOrderDate">
                                {`${moment(transaction_date).format(
                                  "L"
                                )} ${moment(transaction_date).format("LT")}`}
                              </div>
                            </div>
                          </div>
                          <div
                            className="eachMyOrderImgAndTotalAmountContainer"
                            style={{ marginTop: "0.5em" }}
                          >
                            <div className="eachMyOrderImgAndDescContainer">
                              {firstProductItem &&
                                firstProductItem.product_thumbnail_url ? (
                                <img
                                  src={firstProductItem.product_thumbnail_url}
                                  alt="my-order-cover-img"
                                />
                              ) : null}
                              <div
                                className="eachMyOrderDescContainer"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent:
                                    leftItemsCounter >= 1
                                      ? "flex-end"
                                      : "center",
                                }}
                              >
                                {firstProductItem &&
                                  firstProductItem.product_name ? (
                                  <SearchResult
                                    text={firstProductItem.product_name}
                                    searchKey={searchQuery}
                                  />
                                ) : null}

                                {leftItemsCounter >= 1 ? (
                                  <div className="eachMyOrderLeftItemCounter">
                                    {leftItemsCounter === 1
                                      ? `+${leftItemsCounter} more item`
                                      : `+${leftItemsCounter} more items`}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        {!isLastIndex ? (
                          <div
                            className="dividerContent"
                            style={{
                              marginTop: "0.75em",
                              marginBottom: "0.75em",
                            }}
                          ></div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          <div className="allMyOrdersContainer">
            {orders && Array.isArray(orders) && orders.length > 0 ? (
              orders.map((order, index) => {
                const {
                  transaction_id,
                  transaction_date,
                  payment_status,
                  products,
                  transaction_amount,
                } = order || {};
                let firstProductItem;
                if (
                  products &&
                  Array.isArray(products) &&
                  products.length > 0
                ) {
                  firstProductItem = products[0];
                }
                let leftItemsCounter = products.length - 1;
                return (
                  <div
                    className="eachMyOrderContainer"
                    key={index}
                    onClick={() => navigate(`/my-orders/${transaction_id}`)}
                  >
                    <div className="eachMyOrderContentContainer">
                      <div
                        className={
                          isMobileSize
                            ? "eachMyOrderIdAndStatusMobile"
                            : "eachMyOrderIdAndStatus"
                        }
                      >
                        <div className="eachMyOrderIdAndDateContainer">
                          <div className="eachMyOrderId">{`ORDER ID: ${transaction_id}`}</div>
                          <div
                            className="eachMyOrderDate"
                            style={isMobileSize ? { marginLeft: 0 } : {}}
                          >
                            {`${moment(transaction_date).format("L")} ${moment(
                              transaction_date
                            ).format("LT")}`}
                          </div>
                        </div>
                        <div className="eachMyOrderStatusContainer">
                          <div>
                            {payment_status &&
                              capitalizeFirstLetter(payment_status)}
                          </div>
                        </div>
                      </div>
                      <div className="eachMyOrderImgAndTotalAmountContainer">
                        <div
                          className={
                            isMobileSize
                              ? "eachMyOrderImgAndDescContainerMobile"
                              : "eachMyOrderImgAndDescContainer"
                          }
                        >
                          {firstProductItem &&
                            firstProductItem.product_thumbnail_url ? (
                            <img
                              src={firstProductItem.product_thumbnail_url}
                              alt="my-order-cover-img"
                            />
                          ) : null}
                          <div
                            className="eachMyOrderDescContainer"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent:
                                leftItemsCounter >= 1 ? "flex-end" : "center",
                            }}
                          >
                            <div className="eachMyOrderCoverItemName">
                              {firstProductItem &&
                                firstProductItem.product_name}
                            </div>
                            {leftItemsCounter >= 1 ? (
                              <div className="eachMyOrderLeftItemCounter">
                                {leftItemsCounter === 1
                                  ? `+${leftItemsCounter} more item`
                                  : `+${leftItemsCounter} more items`}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="eachMyOrderTotalAmountContainer">
                          <div className="eachMyOrderTotalAmountLabel">
                            TOTAL AMOUNT
                          </div>
                          <div className={ isMobileSize  ? "eachMyOrderTotalAmountLabel" : ""
                              }>{`${searchState?.currencyVal?.code} ${formatPrice(transaction_amount)}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="emptyWishlistContainer">
                <img src={EmptyWishlistIcon} alt="empty-wishlist-icon" />
                <div>No order yet.</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            className="backToPreviousPageContainer"
            onClick={() => handleClickBackToPreviousPage()}
            style={{ marginTop: isMobileSize ? "0.25em" : "0.5em" }}
          >
            <img
              src={BackToPreviousPageIcon}
              alt="my-order-back-to-previous-page"
            />
            <div>{isMobileSize ? "Back" : "Back to previous page"}</div>
          </div>
          <div className="myOrderDetailMainContainer">
            {/* {orderDetailViewForPDF && (
              <Pdf orderDetail={orderDetail} searchState={searchState} />
            )} */}
            <div
              id="myOrderDetailContentContainer"
              className="myOrderDetailContentContainer"
            >
              <div className="flex flex-col sm:flex-row justify-between gap-4">
                <div className="flex flex-col sm:flex-row  gap-4">
                  <div>
                    <div className="mb-2 font-semibold" style={{ fontSize: isMobileSize ? "12px" : "14px" }}>
                      Status:
                    </div>
                    <div
                      className="eachMyOrderStatusContainer w-min py-1 px-2"
                      style={{ fontSize: isMobileSize ? "12px" : "14px" }}
                    >

                      {orderDetail && orderDetail.payment_status
                        ? capitalizeFirstLetter(orderDetail.payment_status)
                        : ""}
                    </div>
                    {orderDetail && orderDetail.shipmentNumber && (
                      <div>{`Shipment no. ${orderDetail.shipmentNumber}`}</div>
                    )}
                  </div>
                  {orderDetail?.expiry_time && (
                    <div>
                      <div className="mb-2 font-semibold" style={{ fontSize: isMobileSize ? "12px" : "14px" }}>
                        Expiry time:
                      </div>
                      <div
                        style={{ fontSize: isMobileSize ? "12px" : "14px" }}
                      >

                        <div>
                          {moment(orderDetail?.expiry_time).format("LLL")}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col sm:flex-row items-start space-y-2 sm:space-y-0 sm:space-x-2">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      gap: "1em",
                    }}
                  >
                    {orderDetail &&
                      orderDetail.mpayment_link &&
                      orderDetail.payment_status &&
                      orderDetail.payment_status === "pending" ? (
                      <div
                        className="printInvoiceBtnContainer"
                        onClick={() => window.location.replace(orderDetail.mpayment_link)}
                        style={{
                          background: "black",
                        }}
                      >
                        <div className="printInvoiceContentContainer">
                          <div style={{ color: "white", fontWeight: "bold" }}>Pay Now</div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <button
                    className="printInvoiceBtnContainer"
                    onClick={() => window.open(orderDetail?.invoice?.file_url)}
                  >
                    <div className="printInvoiceContentContainer">
                      <img src={DownloadIcon} alt="print-invoice" />
                      <div>Invoice</div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="myOrderDetailBodyContainer">
                <div className="eachMyOrderIdAndStatus">
                  <div className="eachMyOrderIdAndDateContainer">
                    {orderDetail && orderDetail.transaction_id ? (
                      <div className="eachMyOrderId">{`ORDER ID: ${orderDetail.transaction_id}`}</div>
                    ) : null}
                    {orderDetail && orderDetail.date ? (
                      <div className="eachMyOrderDate">
                        {`${moment(orderDetail.date).format("L")} ${moment(
                          orderDetail.date
                        ).format("LT")}`}
                      </div>
                    ) : null}
                  </div>
                  {orderDetail &&
                    orderDetail.shipping_address &&
                    orderDetail.shipping_address.address_name ? (
                    <div className="myOrderStatusSendToAndDropdownInputContainer">
                      <div>Send to</div>
                      <div className="myOrderSendToValueAddressDetailContainer">
                        <div
                          className="eachAddressContainer"
                          style={
                            isMobileSize
                              ? { marginTop: "0", width: "47.9vw" }
                              : { marginTop: "0", width: "17.5vw" }
                          }
                        >
                          <div
                            style={{
                              padding: isMobileSize
                                ? "1vh 3vw 1vh 3vw"
                                : "1vh 1.5vw 1vh 1.5vw",
                            }}
                          >
                            {orderDetail.shipping_address.address_name ? (
                              <div className="eachAddressNameStyle">
                                {orderDetail.shipping_address.address_name}
                              </div>
                            ) : null}
                            {orderDetail.shipping_address.country &&
                              orderDetail.shipping_address.country.phone_code &&
                              orderDetail.shipping_address.phone_number ? (
                              <div className="eachStreetNameAndCountryDescStyle">
                                {`${orderDetail.shipping_address.country.phone_code}${orderDetail.shipping_address.phone_number}`}
                              </div>
                            ) : null}
                            {orderDetail.shipping_address.address_details &&
                              orderDetail.shipping_address.subdistrict_name &&
                              orderDetail.shipping_address.city_name &&
                              orderDetail.shipping_address.postal_code ? (
                              <div
                                className="eachStreetNameAndCountryDescStyle"
                                style={{
                                  marginTop: isMobileSize ? "1vh" : "0.75vh",
                                }}
                              >
                                {`${orderDetail.shipping_address.street_address ? `${orderDetail.shipping_address.street_address}` : ''} ${orderDetail.shipping_address.address_details} ${orderDetail.shipping_address.subdistrict_name} ${orderDetail.shipping_address.city_name} ${orderDetail.shipping_address.postal_code}`}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="allItemsContainer">
                  {orderDetail &&
                    orderDetail.products &&
                    Array.isArray(orderDetail.products) &&
                    orderDetail.products.length > 0 &&
                    orderDetail.products.map((item, index) => {
                      const {
                        product_name,
                        product_price,
                        qty,
                        product_thumbnail_url,
                        product_variant_option
                      } = item || {};
                      return (
                        <div
                          key={index}
                          className="eachOrderItemDetailContainer"
                        >
                          <div
                            style={{
                              marginTop: "1.5em",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className={
                                isMobileSize
                                  ? "eachMyOrderImgAndDescContainerMobile"
                                  : "eachMyOrderImgAndDescContainer"
                              }
                            >
                              <img
                                src={product_thumbnail_url}
                                alt="order-detail-img"
                              />
                              <div
                                className="eachMyOrderDescContainer"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: isMobileSize
                                    ? "space-between"
                                    : "center",
                                  fontSize: isMobileSize ? "13px" : "16px",
                                }}
                              >
                                <div
                                  className="eachMyOrderCoverItemName"
                                  style={
                                    isMobileSize ? { marginBottom: 0 } : {}
                                  }
                                >
                                  <span className="font-medium">{`${qty} x`} {product_name}</span>
                                  <br />
                                  <span className="text-xs sm:text-sm">{product_variant_option}</span>
                                </div>
                                {isMobileSize ? (
                                  <div
                                    className="eachMyOrderTotalAmountContainer"
                                    style={{ marginTop: 0 }}
                                  >
                                    <div>{`${(searchState &&
                                      searchState.currencyVal &&
                                      searchState.currencyVal.code) ||
                                      ""
                                      } ${product_price && qty
                                        ? formatPrice(+product_price)
                                        : 0
                                      }`}</div>
                                  </div >
                                ) : null}
                              </div >
                            </div >
                            {!isMobileSize ? (
                              <div className="eachMyOrderTotalAmountContainer">
                                <div>{`${(searchState &&
                                  searchState.currencyVal &&
                                  searchState.currencyVal.code) ||
                                  ""
                                  } ${product_price && qty
                                    ? formatPrice(+product_price)
                                    : 0
                                  }`}</div>
                              </div >
                            ) : null}
                          </div >
                        </div >
                      );
                    })}
                </div >
                <div
                  className="dividerMyOrderDetail"
                  style={{ marginTop: isMobileSize ? "1em" : "2em" }}
                />
                <div className="myOrderDetailFooterContainer">
                  {orderDetail && orderDetail.products ? (
                    <div className="myOrderDetailTotalItemStyle">
                      {orderDetail.products.length > 1
                        ? `${orderDetail.products.length} items in total`
                        : `${orderDetail.products.length} item in total`}
                    </div>
                  ) : null}
                  <div className="myOrderDetailDeliveryFeeAndTotalAmountMainContainer">
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div>Tax (11%)</div>
                      <div>{searchState?.currencyVal?.code} {formatPrice(orderMetadata?.pricing?.tax)}</div>
                    </div >
                    <div className="text-sm mt-4 mb-2">
                      <div className="font-bold">Payment</div>
                    </div>
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div>Payment method</div>
                      <div>{orderDetail?.payment_channel?.name}</div>
                    </div>
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div>Transaction fee</div>
                      <div>{searchState?.currencyVal?.code} {formatPrice(orderMetadata?.pricing?.transaction_fee)}</div>
                    </div>
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div>Transaction tax</div>
                      <div>{searchState?.currencyVal?.code} {formatPrice(orderMetadata?.pricing?.transaction_tax)}</div>
                    </div>
                    <div className="text-sm mt-4 mb-2">
                      <div className="font-bold">Shipping</div>
                    </div>
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div className="">Shipping method</div>
                      <div className="text-right">{orderDetail?.shipping_method?.name} - {orderDetail?.shipping_service}</div>
                    </div>
                    <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                      <div>Shipping fee</div>
                      <div>{`${searchState?.currencyVal?.code} ${orderDetail && orderDetail.shipping_cost
                        ? formatPrice(orderDetail.shipping_cost)
                        : 0
                        }`}</div>
                    </div>
                    {
                      orderMetadata?.pricing?.discount > 0 && (
                        <>
                          <div className="text-sm mt-4 mb-2">
                            <div className="font-bold">Voucher discount</div>
                          </div>
                          <div className="myOrderDetailDeliveryFeeAndTotalAmountContainer">
                            <div className="">Voucher</div>
                            <div className="text-right">- {searchState?.currencyVal?.code} {formatPrice(orderMetadata?.pricing?.discount)}</div>
                          </div>
                        </>
                      )
                    }
                    <div
                      className="myOrderDetailDeliveryFeeAndTotalAmountContainer font-bold text-sm mt-4"
                      style={isMobileSize ? { marginBottom: 0 } : {}}
                    >
                      <div>
                        Total Amount
                      </div>
                      <div>
                        {searchState?.currencyVal?.code} {formatPrice(orderDetail.transaction_amount)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div >
  );
};

export default MyOrdersTab;
