import React from "react";

const About = () => {
  return (
    <div>
      <section className="bg-maplexxon-grey">
        <div className="max-w-main pt-24 pb-20 lg:pt-40 lg:pb-40 flex flex-col items-center main-container">
          <h1 className="max-w-lg text-center text-xl lg:text-3xl leading-normal font-bold text-maplexxon-blue maplexxon-animation-end">
            Helping your business grow without hassle
          </h1>
        </div>
      </section>
      <section>
        <div className="max-w-main py-8 lg:py-16 flex flex-col items-center">
          <h1 className="max-w-xl text-center text-xl lg:text-3xl mb-4 lg:mb-8 font-bold text-maplexxon-blue maplexxon-animation-end">
            About Moonlight
          </h1>
          <p className="max-w-2xl text-center text-base lg:text-xl maplexxon-animation-end">
            <span className="font-bold"> Moonlight Store</span> is a B2B Software as a Service (SaaS) company that offers ready-made solutions, to help small to medium sized of business achieve success. We envision helping businesses to optimize their operations, enabling them to work smarter, faster and more efficient.
          </p>
          <div className="max-w-2xl text-center mt-6 sm:mt-12 space-y-2 sm:space-y-4">
            <div className="space-y-1 sm:space-y-2 maplexxon-animation-end">
              <p className="text-lg lg:text-2xl font-bold">Vision</p>
              <p className="text-base lg:text-xl">Build software solutions for businesses to thrive in digital age.</p>
            </div>
            <div className="space-y-1 sm:space-y-2 maplexxon-animation-end">
              <p className="text-lg lg:text-2xl font-bold">Mission</p>
              <p className="text-base lg:text-xl">Simplifying process, scaling up your business.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default About;