import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// React Prismazoom
import PrismaZoom from 'react-prismazoom'

// Images and Icons
import AddToWishListIcon from "../../assets/itemImagesAndIcons/AddToWishListIcon.png";
import AddedToWishlistIcon from "../../assets/itemImagesAndIcons/AddedToWishlistIcon.png";
import ArrowDownIcon from "../../assets/navbarIcons/ArrowDownIcon.png";
import ArrowUpIcon from "../../assets/navbarIcons/ArrowUpIcon.png";

// Styles
import "../../styles/itemDetails.css";

// Components
import Scroller from "../../components/Scroller";
import FullscreenView from "../../components/FullscreenView";
import { InputText } from "../../components/Inputs";

// Apis
import {
  getAllProducts,
  getCombinationPriceAndStock,
  getDetailProduct,
} from "../../api/products";
import { createNewWishlist, deleteWishlist } from "../../api/wishlist";
import { addProductToCart } from "../../api/carts";

// Helpers
import { timer } from "../../helpers/timer";
import { formUrlQuery } from "../../helpers/format-queries";
import { formatPrice } from "../../helpers/format-price";
import { handleFetchUsersShoppingCarts } from "../../helpers/handleFetchUsersShoppingCarts";

const ItemDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { itemId } = useParams();

  const userDetail = useSelector((state) => state.userDetail);
  const authenticationModal = useSelector((state) => state.authenticationModal);
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);
  const wishlist = useSelector((state) => state.wishlist);
  const skeletonLoader = useSelector((state) => state.skeletonLoader);
  const shoppingCarts = useSelector((state) => state.shoppingCarts);

  const [itemDetails, setItemDetails] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [arrItemDetailDescriptions, setArrItemDetailDescriptions] = useState([]);
  const [isOpenViewAllSize, setIsOpenViewAllSize] = useState(false);
  const [selectedCombination, setSelectedCombination] = useState({
    combinationId: null,
    variant_opt_ids: [],
  });
  const [isProductAlreadyInWishlist, setIsProductAlreadyInWishlist] =
    useState(false);

  const [quantity, setQuantity] = useState({
    qty: 0,
    stock: 0,
    onMouseOverMinus: false,
    onMouseOverPlus: false,
  });

  const [selectedVariantProduct, setSelectedVariantProduct] = useState([])

  const productImageRef = useRef([]);

  // useEffect(() => {
  //   if (variantList) {
  //     setSelectedVariantProduct([])
  //   }
  // }, [variantList])

  useEffect(() => {
    if (itemId) {
      handleFetchItemDetail(itemId);
    }
  }, [itemId, searchState?.currencyVal?.id]);

  useEffect(() => {
    if (userDetail) {
      if (wishlist && Array.isArray(wishlist) && wishlist.length > 0) {
        handleSetIsProductAlreadyInWishlist(wishlist, itemId);
      }
    }
  }, [itemId, wishlist, userDetail]);

  useEffect(() => {
    productImageRef.current = productImageRef.current.slice(0, itemDetails?.images?.length);
 }, [itemDetails?.images]);

  const handleSetNewStockValueForProduct = async (itemId, objCombination) => {
    try {
      if (objCombination) {
        let arrVariantOptIds = objCombination.variant_opt_ids;
        let body = {
          product_id: itemId,
        };
        if (
          arrVariantOptIds &&
          Array.isArray(arrVariantOptIds) &&
          arrVariantOptIds.length > 0
        ) {
          body = {
            ...body,
            variant_option_ids: arrVariantOptIds,
          };
          if (quantity && quantity.qty && quantity.qty !== 0) {
            body = {
              ...body,
              qty: quantity.qty,
            };
          }
          dispatch({
            type: "SET_IS_LOADING",
            isLoading: true,
          });
          const result = await getCombinationPriceAndStock(body);
          if (result && result.data) {
            const {
              data: {
                combination_id,
                stock,
                final_price,
                original_price,
                applicable_discount,
              } = {},
            } = result || {};
            if (combination_id) {
              setQuantity({ ...quantity, qty: 1, stock });
              setSelectedCombination({
                ...objCombination,
                combinationId: combination_id,
              });
              setItemDetails({
                ...itemDetails,
                combination_price: original_price,
                combination_final_price: final_price,
              });
            } else {
              setQuantity({
                ...quantity,
                qty: itemDetails.qty,
                stock: itemDetails.qty,
              });
              setSelectedCombination({
                ...objCombination,
                combinationId: null,
              });
              setItemDetails({
                ...itemDetails,
                combination_price: null,
                combination_final_price: null,
              });
            }
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
          }
        } else {
          setQuantity({
            ...quantity,
            qty: itemDetails.qty,
            stock: itemDetails.qty,
          });
          setSelectedCombination({
            ...objCombination,
            combinationId: null,
          });
          setItemDetails({
            ...itemDetails,
            combination_price: null,
            combination_final_price: null,
          });
        }
      }
    } catch (err) {
      console.log(err.response.data, "error <<<<");
    }
  };

  const handleSetIsProductAlreadyInWishlist = async (usersWishlist, itemId) => {
    let isProductAlreadyInWishlist = false;
    usersWishlist.forEach((wishlist) => {
      if (
        wishlist &&
        wishlist.product &&
        wishlist.product.id &&
        wishlist.product.id === parseInt(itemId)
      ) {
        isProductAlreadyInWishlist = true;
      }
    });
    setIsProductAlreadyInWishlist(isProductAlreadyInWishlist);
  };

  const handleCheckAvailabilty = (array, value) => {
    let alreadyExist = array.some((obj) => {
      const { variant_option: { name } = {} } = obj || {};
      return value === name;
    });
    return alreadyExist;
  };

  const handleFetchItemDetail = async (itemId) => {
    dispatch({
      type: "SET_SKELETON_LOADER",
      skeletonLoader: true,
    });

    const queries = formUrlQuery({
      currency_id: searchState.currencyVal.id
    })

    const result = await getDetailProduct(itemId, queries);
    if (result && result.data) {
      const {
        data,
        data: {
          combinations,
          sub_category,
          product_discounts,
          product_informations,
        } = {},
      } = result || {};

      let finalArrItemDetailDescriptions = product_informations;

      if (
        finalArrItemDetailDescriptions &&
        Array.isArray(finalArrItemDetailDescriptions) &&
        finalArrItemDetailDescriptions.length > 0
      ) {
        finalArrItemDetailDescriptions = finalArrItemDetailDescriptions.map(
          (itemDesc) => {
            itemDesc["isOpenDetailDescription"] = false;
            return itemDesc;
          }
        );
      }

      setArrItemDetailDescriptions(finalArrItemDetailDescriptions);

      let arrYouMayAlsoLike = await handleFetchYouMayAlsoLikeList(
        sub_category.id,
        sub_category.category_id,
        itemId
      );

      let objCombination = selectedCombination;

      if (
        combinations &&
        Array.isArray(combinations) &&
        combinations.length > 0
      ) {
        let finalVariantList = {};
        combinations.forEach((combination) => {
          const { combination_options, qty: combinationQty } = combination;
          if (
            combination_options &&
            Array.isArray(combination_options) &&
            combination_options.length > 0
          ) {
            combination_options.forEach((combination_option) => {
              const { variant, variant_option } = combination_option;
              combination_option.qty = combinationQty
              if (variant) {
                const { name } = variant || {};
                if (!finalVariantList[`${name}`]) {
                  combination_option.isSelected = false;
                  finalVariantList = {
                    ...finalVariantList,
                    [`${name}`]: [combination_option],
                  };
                } else {
                  combination_option.isSelected = false;
                  let alreadyExist = handleCheckAvailabilty(
                    finalVariantList[`${name}`],
                    variant_option.name
                  );
                  if (!alreadyExist) {
                    finalVariantList[`${name}`].push(combination_option);
                  }
                }
              }
            });
          }
        });
        setVariantList(
          Object.keys(finalVariantList).length === 0 ? null : finalVariantList
        );

        if (Object.keys(finalVariantList) && Object.keys(finalVariantList).length > 0) {
          for (let key in finalVariantList) {
            let defaultSelectedVariant = finalVariantList[key][0]
            if (defaultSelectedVariant) {
              let { variant_option: { id, name } = {} } = defaultSelectedVariant || {}
              defaultSelectedVariant.isSelected = true
              let finalVariantOptIds = objCombination.variant_opt_ids
              finalVariantOptIds.push(id)
              objCombination = {
                ...objCombination,
                [`${key}`]: name,
                variant_opt_ids: finalVariantOptIds,
              };
              let variantSelectArr = selectedVariantProduct
              if (!variantSelectArr.includes(name)) {
                variantSelectArr.push(name)
                setSelectedVariantProduct(variantSelectArr)
              }
            }
          }
        }
      }

      // Set default selected variant product if product have combination
      let defaultCombinationStock = 0
      let defaultCombinationPrice = 0
      let defaultCombinationFinalPrice = 0

      if (objCombination.variant_opt_ids && objCombination.variant_opt_ids.length > 0) {
        // Check if data duplicate in array variant option
        objCombination.variant_opt_ids = objCombination.variant_opt_ids.filter((value, index, array) => array.indexOf(value) === index)

        const combinationPriceStockRes = await getCombinationPriceAndStock({ product_id: itemId, variant_option_ids: objCombination.variant_opt_ids });

        if (combinationPriceStockRes && combinationPriceStockRes.data) {
          const { combination_id, stock, original_price, final_price } = combinationPriceStockRes.data || {}
          if (combination_id) {
            defaultCombinationStock = stock
            defaultCombinationPrice = original_price
            defaultCombinationFinalPrice = final_price
            setSelectedCombination({
              ...objCombination,
              combinationId: combination_id,
            });
          }
        }
      }

      setQuantity({
        ...quantity,
        qty: data.qty > 0 ? 1 : defaultCombinationStock > 0 ? 1 : data.qty,
        stock: data.qty > 0 ? data.qty : defaultCombinationStock > 0 ? defaultCombinationStock : data.qty,
      });
      let objItemDetails = {
        ...data,
        product_discounts:
          product_discounts &&
            Array.isArray(product_discounts) &&
            product_discounts.length > 0
            ? product_discounts[0]
            : null,
        youMayAlsoLike: arrYouMayAlsoLike,
        combination_price: defaultCombinationPrice > 0 ? defaultCombinationPrice : null,
        combination_final_price: defaultCombinationFinalPrice > 0 ? defaultCombinationFinalPrice : null
      };
      setItemDetails(objItemDetails);
      dispatch({
        type: "SET_SKELETON_LOADER",
        skeletonLoader: false,
      });
    }
  };

  const handleFetchYouMayAlsoLikeList = async (
    subCategoryId,
    categoryId,
    itemId
  ) => {
    let queries = {};
    if (subCategoryId) {
      queries.category_id = categoryId;
    }
    if (searchState && searchState.currencyVal && searchState.currencyVal.id) {
      queries.currency_id = searchState.currencyVal.id;
    }
    if (itemId) {
      queries.exclude_product_id = itemId;
    }
    queries = formUrlQuery(queries);
    const result = await getAllProducts(queries);
    if (result && result.data) {
      const { data } = result || {};
      return data;
    } else {
      return [];
    }
  };

  const handleClickVariantOption = (propValue, keyValue, index) => {
    let objCombination = selectedCombination;
    let variantSelectArr = selectedVariantProduct
    let finalArrKeyNameOptions = variantList[`${keyValue}`];
    let totalVariant = Object.keys(variantList).length
    finalArrKeyNameOptions = finalArrKeyNameOptions.map((option) => {
      const { variant_option: { id, name } = {}, isSelected, variant: { name: variantName } = {} } = option || {};
      if (propValue === name) {
        option.isSelected = true;
        if (option.isSelected) {
          // select variant option will add variant opt ids
          let finalVariantOptIds = objCombination.variant_opt_ids;
          if (!finalVariantOptIds.includes(id)) {
            finalVariantOptIds.push(id);
          }
          objCombination = {
            ...objCombination,
            [`${keyValue}`]: propValue,
            variant_opt_ids: finalVariantOptIds,
          };
          if (totalVariant > 1) {
            if (!variantSelectArr.includes(name)) {
              if (variantName == 'Type') {
                variantSelectArr = [name, ...variantSelectArr]
              } else {
                variantSelectArr.splice(index + 1, 0, name)
              }
            }
          }
        } else {
          // unselect variant option will remove variant opt ids
          let finalVariantOptIds = objCombination.variant_opt_ids;
          finalVariantOptIds =
            finalVariantOptIds &&
              Array.isArray(finalVariantOptIds) &&
              finalVariantOptIds.length > 0
              ? finalVariantOptIds.filter((variantOptId) => variantOptId !== id)
              : [];
          objCombination = {
            ...objCombination,
            [`${keyValue}`]: null,
            variant_opt_ids: finalVariantOptIds,
          };

          if (totalVariant > 1) {
            variantSelectArr = variantSelectArr.filter(item => item != name)
          }
        }
      } else {
        // remove variant opt id where isSelected variant === false
        option.isSelected = false;
        let finalVariantOptIds = objCombination.variant_opt_ids;
        finalVariantOptIds =
          finalVariantOptIds &&
            Array.isArray(finalVariantOptIds) &&
            finalVariantOptIds.length > 0
            ? finalVariantOptIds.filter((variantOptId) => variantOptId !== id)
            : [];
        objCombination = {
          ...objCombination,
          variant_opt_ids: finalVariantOptIds,
        };

        if (totalVariant > 1) {
          variantSelectArr = variantSelectArr.filter(item => item != name)
        }
      }
      return option;
    });
    setSelectedVariantProduct(variantSelectArr)
    setSelectedCombination(objCombination);
    handleSetNewStockValueForProduct(itemId, objCombination);
  };

  const handleOnClickArrowIcon = (propTitle) => {
    let finalArrItemDetailDescriptions = arrItemDetailDescriptions;
    finalArrItemDetailDescriptions = finalArrItemDetailDescriptions.map(
      (detailDescription) => {
        const { title, isOpenDetailDescription } = detailDescription || {};
        if (propTitle === title) {
          detailDescription.isOpenDetailDescription = !isOpenDetailDescription;
        }
        return detailDescription;
      }
    );
    setArrItemDetailDescriptions(finalArrItemDetailDescriptions);
  };

  const handleOnClickPlusMinusQty = (flagger, quantity, value) => {
    const itemInCartQty = shoppingCarts?.find(cart => cart?.product_id === itemDetails?.id)?.qty || 0

    if (flagger === "plus") {
      if (itemInCartQty + quantity?.qty + 1 <= quantity.stock) {
        setQuantity({ ...quantity, qty: quantity.qty + 1 });
      }
    } else if (flagger === "minus") {
      if (quantity && quantity.qty > 1) {
        setQuantity({ ...quantity, qty: quantity.qty - 1 });
      }
    } else {
      value = parseInt(value);
      if (!value) {
        setQuantity({ ...quantity, qty: "" });
      } else {
        if (isNaN(value)) {
          setQuantity({ ...quantity, qty: "" });
        } else {
          const { stock } = quantity || {};
          if (itemInCartQty + value <= stock) {
            setQuantity({ ...quantity, qty: value });
          } else {
            setQuantity({ ...quantity, qty: stock - itemInCartQty });
          }
        }
      }
    }
  };

  const handleOnClickAddToWishlist = async (itemId) => {
    if (userDetail) {
      if (isProductAlreadyInWishlist) {
        let userWishlistObj =
          wishlist && Array.isArray(wishlist) && wishlist.length > 0
            ? wishlist.filter((el) => {
              return el.product_id === itemId;
            })
            : null;
        userWishlistObj =
          userWishlistObj &&
            Array.isArray(userWishlistObj) &&
            userWishlistObj.length > 0
            ? userWishlistObj[0]
            : null;
        if (userWishlistObj && userWishlistObj.id) {
          dispatch({
            type: "SET_IS_LOADING",
            isLoading: true,
          });
          const result = await deleteWishlist(userWishlistObj.id);
          if (result && result.data) {
            dispatch({
              type: "SET_IS_LOADING",
              isLoading: false,
            });
            let finalUsersWishlist = wishlist;
            finalUsersWishlist = finalUsersWishlist.filter((usrWishlist) => {
              return usrWishlist.id !== userWishlistObj.id;
            });
            dispatch({ type: "SET_WISHLIST", wishlist: finalUsersWishlist });
            const beforeFunction = () => {
              return dispatch({
                type: "SET_NOTIFICATION",
                notification: {
                  success: true,
                  msg: "Successfully removed from your wishlist",
                  show: true,
                },
              });
            };
            const afterFunction = () => {
              return dispatch({
                type: "SET_NOTIFICATION",
                notification: {
                  success: false,
                  msg: "",
                  show: false,
                },
              });
            };
            timer(beforeFunction, afterFunction, 3000);
            setIsProductAlreadyInWishlist(false);
          }
        }
      } else {
        let qty = quantity && quantity.qty;
        dispatch({
          type: "SET_IS_LOADING",
          isLoading: true,
        });
        const result = await createNewWishlist({ product_id: itemId, qty });
        if (result && result.data) {
          dispatch({
            type: "SET_IS_LOADING",
            isLoading: false,
          });
          let finalUsersWishlist = wishlist;
          finalUsersWishlist.push(result.data);
          dispatch({ type: "SET_WISHLIST", wishlist: finalUsersWishlist });
          const beforeFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: true,
                msg: "Successfully added to your wishlist",
                show: true,
              },
            });
          };
          const afterFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: false,
                msg: "",
                show: false,
              },
            });
          };
          timer(beforeFunction, afterFunction, 3000);
          setIsProductAlreadyInWishlist(true);
        }
      }
    } else {
      dispatch({
        type: "SET_AUTHENTICATION_MODAL",
        authenticationModal: {
          ...authenticationModal,
          login: true,
        },
      });
    }
  };

  const handleClickAddToCart = async (quantity, itemDetails) => {
    if (userDetail) {
      let itemInCartQty = shoppingCarts?.find(cart => cart?.product_id === itemDetails?.id)?.qty || 0
      const body = {
        qty: quantity && quantity.qty,
        product_id: itemDetails && itemDetails.id,
      };
      if (itemDetails?.combinations?.length > 0 && selectedCombination?.combinationId) {
        body.combination_id = selectedCombination && selectedCombination.combinationId;
        itemInCartQty = shoppingCarts?.find(cart => cart?.product_id === itemDetails?.id && cart?.combination?.id === selectedCombination.combinationId)?.qty || 0
      }
      const totalQty = itemInCartQty + quantity?.qty

      if (totalQty <= quantity.stock) {
        dispatch({
          type: "SET_IS_LOADING",
          isLoading: true,
        });

        const result = await addProductToCart(body);
        if (result && result.data) {
          handleFetchUsersShoppingCarts(searchState, dispatch);

          dispatch({
            type: "SET_IS_LOADING",
            isLoading: false,
          });
          const beforeFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: true,
                msg: "Successfully added to shopping cart",
                show: true,
              },
            });
          };
          const afterFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: false,
                msg: "",
                show: false,
              },
            });
          };
          timer(beforeFunction, afterFunction, 3000);
          setQuantity(prev => ({
            ...prev,
            qty: 1,
          }))
        }
      } else {
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "Product quantity cannot exceed product stock",
              show: true,
            },
          })
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
      }
    } else {
      dispatch({
        type: "SET_AUTHENTICATION_MODAL",
        authenticationModal: {
          ...authenticationModal,
          login: true,
        },
      });
    }
  };

  const properties = {
    prevArrow: (
      <button
        className="arrow arrow-left"
        style={{
          position: "absolute",
          zIndex: "1",
          cursor: "pointer",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          color: "#000000",
          fontSize: isMobileSize ? "35px" : "40px",
          padding: isMobileSize ? "0px" : "20px",
          borderRadius: "50%",
          left: 10,
        }}
      >
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{
          position: "absolute",
          zIndex: "1",
          cursor: "pointer",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          color: "#000000",
          fontSize: isMobileSize ? "35px" : "40px",
          padding: isMobileSize ? "0px" : "20px",
          borderRadius: "50%",
          right: 10,
        }}
      >
        <span>&gt;</span>
      </button>
    ),
  };

  const handleOnClickBrand = (brand) => {
    if (searchState['category']) delete searchState['category']

    if (searchState['subCategories']) {
      delete searchState['subCategories']
      delete searchState['subCategory']
    }

    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: {
        ...searchState,
        otherFilter: {
          ...searchState.otherFilter,
          brandNames: [brand.name],
        },
      },
    });
    navigate("/items");
  };

  const handleShowProductImages = (index) => {
    productImageRef?.current[index].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  }

  return (
    <div>
      <div className="itemDetailContainer">
        <div className="itemDetailContainerContent">
          <div className="itemDetailImages">
            {skeletonLoader ? (
              <div className="loading-btn w-full h-64 md:h-72 lg:h-96 bg-slate-300 rounded-3xl"></div>
            ) : !skeletonLoader &&
              itemDetails &&
              itemDetails.images &&
              Array.isArray(itemDetails.images) &&
              itemDetails.images.length > 0 ? (
              <Slide {...properties} arrows={itemDetails?.images?.length > 1} autoplay={false}>
                {itemDetails.images.map((image, index) => {
                  const { image_url } = image;
                  return (
                    <div key={index} className="sliderImageStyle">
                      <PrismaZoom>
                        <img
                          src={image_url}
                          alt={`dummy slide ${index + 1}`}
                          ref={el => productImageRef.current[index] = el}
                        />
                      </PrismaZoom>
                    </div>
                  );
                })}
              </Slide>
            ) : null}
            {skeletonLoader && !isMobileSize ? (
              <div className="otherImagesContainer">
                <div className="loading-btn eachOtherImage h-64 md:h-72 lg:h-80 bg-slate-300 rounded-3xl"></div>
                <div className="loading-btn eachOtherImage h-64 md:h-72 lg:h-80 bg-slate-300 rounded-3xl"></div>
              </div>
            ) : !skeletonLoader &&
              !isMobileSize &&
              itemDetails &&
              itemDetails.images &&
              Array.isArray(itemDetails.images) &&
              itemDetails.images.length > 0 ? (
              <div className="otherImagesContainer">
                {itemDetails.images.map((image, index) => {
                  const { image_url } = image;
                  return (
                    <div key={index} className="eachOtherImage" onClick={() => handleShowProductImages(index)}>
                      {/* <PrismaZoom>
                        <img
                          src={image_url}
                          alt={`dummy slide ${index + 1}`}
                        />
                      </PrismaZoom> */}
                        <img
                          src={image_url}
                          alt={`dummy slide ${index + 1}`}
                        />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="itemDetailDescription">
            {skeletonLoader ? (
              <div className="itemDetailDescriptionBrand">
                <div className="loading-btn w-32 md:w-28 lg:w-40 h-6 md:h-4 lg:h-7 bg-slate-300 rounded-xl"></div>
              </div>
            ) : (
              <div
                className="itemDetailDescriptionBrand"
                onClick={() => handleOnClickBrand(itemDetails.brand)}
              >
                {itemDetails && itemDetails.brand && itemDetails.brand.name
                  ? itemDetails.brand.name
                  : ""}
              </div>
            )}
            {skeletonLoader ? (
              <div className="itemDetailDescriptionItemName">
                <div className="loading-btn w-32 md:w-28 lg:w-40 h-6 md:h-4 lg:h-7 bg-slate-300 rounded-xl"></div>
              </div>
            ) : (
              <div className="itemDetailDescriptionItemName">
                {itemDetails && itemDetails.name}
              </div>
            )}
            {skeletonLoader ? (
              <div
                className="itemDetailDescriptionItemDesc"
                style={{
                  paddingTop: isMobileSize ? "1vh" : "0",
                }}
              >
                <div className="loading-btn w-32 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5  bg-slate-300 rounded-xl"></div>
              </div>
            ) : (
              <div className="itemDetailDescriptionItemDesc">
                {itemDetails && itemDetails.description}
              </div>
            )}
            {skeletonLoader ? (
              <div className="itemDetailDescriptionPrice">
                <div className="itemPriceContainer">
                  <div className="loading-btn w-32 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5  bg-slate-300 rounded-xl"></div>
                </div>
              </div>
            ) : (
              <div className="itemDetailDescriptionPrice">
                <div className="itemPriceContainer">
                  <div
                    className={(
                      itemDetails?.product_discounts?.percentage_discount ||
                      (itemDetails?.discounted_price && itemDetails?.discounted_price !== itemDetails?.price) ||
                      (itemDetails?.combination_final_price && itemDetails?.combination_price !== itemDetails?.combination_final_price)
                    ) ? "line-through" : ""}
                  >
                    <span>{searchState?.currencyVal?.code || ""} </span>
                    {
                      itemDetails?.combination_price
                        ? formatPrice(itemDetails?.combination_price)
                        : (itemDetails?.price ? formatPrice(itemDetails?.price) : 0)
                    }
                  </div>
                  {itemDetails?.product_discounts?.percentage_discount ? (
                    <div
                      className="percentageSaleStyle ml-2"
                    >
                      {`${itemDetails?.product_discounts?.percentage_discount}% OFF`}
                    </div>
                  ) : 
                    selectedCombination?.combinationId && 
                    itemDetails?.combinations && 
                    itemDetails?.combinations.length > 0 &&
                    itemDetails?.combinations.filter((combination) => combination?.id == selectedCombination.combinationId).length > 0 &&
                    itemDetails?.combinations.filter((combination) => combination?.id == selectedCombination.combinationId)[0]?.discounts &&
                    itemDetails?.combinations.filter((combination) => combination?.id == selectedCombination.combinationId)[0]?.discounts.length > 0 &&
                    itemDetails?.combinations.filter((combination) => combination?.id == selectedCombination.combinationId)[0]?.discounts[0]?.percentage_discount ? (
                      <div
                        className="percentageSaleStyle ml-2"
                      >
                        {`${itemDetails?.combinations.filter((combination) => combination?.id == selectedCombination.combinationId)[0]?.discounts[0]?.percentage_discount}% OFF`}
                      </div>
                    ) : null
                  }
                </div>

                {itemDetails?.combination_final_price && itemDetails?.combination_price !== itemDetails?.combination_final_price ? (
                  <div className="salePriceStyle">
                    {`${searchState?.currencyVal?.code || ""} ${formatPrice(itemDetails?.combination_final_price)}`}
                  </div>
                ) : !itemDetails?.combination_final_price && itemDetails?.discounted_price ? (
                  <div className="salePriceStyle">
                    {`${searchState?.currencyVal?.code || ""} ${formatPrice(itemDetails?.discounted_price)}`}
                  </div>
                ) : null}
              </div>
            )}
            {!skeletonLoader &&
              selectedVariantProduct &&
              Array.isArray(selectedVariantProduct) &&
              selectedVariantProduct.length > 0 && (
                <div className="variantSelectedContainer">
                  {selectedVariantProduct.join(', ')}
                </div>
              )}
            {!skeletonLoader &&
              variantList &&
              Object.keys(variantList).length > 0 &&
              Object.keys(variantList).map((keyName, i) => {
                return (
                  <div
                    className="itemDetailDescriptionSizeColorQtyContainer"
                    key={i}
                  >
                    <div className="itemDetailDescriptionOptions">
                      <div className="itemDetailDescriptionOptionLabel">
                        {keyName}
                      </div>
                      {/* {keyName && keyName === "Size" && (
                        <div
                          className="itemDetailDescriptionOptionViewSizeGuide"
                          onClick={() => setIsOpenViewAllSize(true)}
                        >
                          View size guide
                        </div>
                      )} */}
                    </div>
                    <div className="itemDetailDescriptionSizeOptions">
                      {variantList[`${keyName}`].sort((prev, next) => prev.variant_option.id > next.variant_option.id ? 1 : next.variant_option.id > prev.variant_option.id ? -1 : 0).map((variantOpt, index) => {
                        const {
                          variant_option: { name = "", image_url } = {},
                          variant: { product_id } = {},
                          isSelected,
                          qty,
                          combination_id
                        } = variantOpt || {};
                        let itemInCartQty = shoppingCarts?.find(cart => {
                          if (combination_id) {
                            return cart?.product_id === product_id && cart?.combination?.id === combination_id
                          } else {
                            return cart?.product_id === product_id
                          }
                        })?.qty || 0
                        const totalQty = itemInCartQty + quantity.qty

                        if (image_url) {
                          return (
                            <div
                              className="eachColorOptionContainer"
                              key={index}
                              style={
                                {
                                  marginBottom: isSelected ? "2.5vh" : "",
                                  pointerEvents: totalQty > qty ? "none" : "",
                                  opacity: totalQty > qty  ? "0.4" : ""
                                }
                              }
                              onClick={() =>
                                handleClickVariantOption(name, keyName, i)
                              }
                            >
                              <img
                                src={image_url}
                                alt={`color-option-${index + 1}`}
                                style={
                                  isSelected
                                    ? {
                                      borderWidth: "3px",
                                      borderStyle: "solid",
                                      borderColor: "#006787",
                                    }
                                    : null
                                }
                              ></img>
                              {
                                isSelected && (
                                  <div>{name}</div>
                                )
                              }
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="eachSizeOptionContainer"
                              key={index}
                              style={{
                                border: `3px solid ${isSelected ? "#006787" : "#d9d9d9"
                                  }`,
                                pointerEvents: totalQty > qty ? "none" : "",
                                opacity: totalQty > qty  ? "0.4" : ""
                              }}
                              onClick={() =>
                                handleClickVariantOption(name, keyName, i)
                              }
                            >
                              <div
                                style={{
                                  fontWeight: `${isSelected ? "600" : "400"}`
                                }}
                              >{name}</div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    {/* {keyName === "Size" ? (
                      <div className="itemDetailDescriptionSizeOptions">
                        {variantList[`${keyName}`].map((sizeOpt, index) => {
                          const {
                            variant_option: { name = "" } = {},
                            isSelected,
                          } = sizeOpt || {};
                          return (
                            <div
                              className="eachSizeOptionContainer"
                              key={index}
                              style={{
                                border: `1px solid ${
                                  isSelected ? "#000000" : "#d9d9d9"
                                }`,
                              }}
                              onClick={() =>
                                handleClickVariantOption(name, keyName)
                              }
                            >
                              <div>{name}</div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="itemDetailDescriptionSizeOptions">
                        {variantList[`${keyName}`].map((colorOpt, index) => {
                          const {
                            variant_option: { name = "", image_url } = {},
                            isSelected,
                          } = colorOpt || {};
                          if (image_url) {
                            return (
                              <div
                                className="eachColorOptionContainer"
                                key={index}
                                onClick={() =>
                                  handleClickVariantOption(name, keyName)
                                }
                              >
                                <img
                                  src={image_url}
                                  alt={`color-option-${index + 1}`}
                                  style={
                                    isSelected
                                      ? {
                                          borderWidth: "2px",
                                          borderStyle: "solid",
                                          borderColor: "#006787",
                                        }
                                      : null
                                  }
                                ></img>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="eachSizeOptionContainer"
                                key={index}
                                style={{
                                  border: `1px solid ${
                                    isSelected ? "#000000" : "#d9d9d9"
                                  }`,
                                }}
                                onClick={() =>
                                  handleClickVariantOption(name, keyName)
                                }
                              >
                                <div>{name}</div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )} */}
                  </div>
                );
              })}
            {!skeletonLoader ? (
              <div className="itemDetailDescriptionSizeColorQtyContainer">
                <div className="itemDetailDescriptionOptions">
                  <div className="itemDetailDescriptionOptionLabel">
                    Quantity
                  </div>
                </div>
                <div className="itemDetailDescriptionSizeOptions">
                  <div className="itemDetailDescriptionQtyOptionsContainer">
                    <div className="itemDetailDescriptionQtyOptions">
                      <div
                        className="itemDetailDescriptionQtyBtn"
                        onMouseOver={() =>
                          quantity && quantity.qty !== 0
                            ? setQuantity({
                              ...quantity,
                              onMouseOverMinus: true,
                            })
                            : null
                        }
                        onMouseLeave={() =>
                          setQuantity({ ...quantity, onMouseOverMinus: false })
                        }
                        onClick={() =>
                          handleOnClickPlusMinusQty("minus", quantity)
                        }
                        style={
                          quantity && quantity.qty !== 0
                            ? {
                              color:
                                quantity && quantity.onMouseOverMinus
                                  ? "#000000"
                                  : "#d9d9d9",
                              cursor: "pointer",
                            }
                            : {
                              color:
                                quantity && quantity.onMouseOverMinus
                                  ? "#000000"
                                  : "#d9d9d9",
                              pointerEvents: "none",
                            }
                        }
                      >
                        -
                      </div>
                      <div className="itemDetailDescriptionQtyValue">
                        <InputText
                          width={isMobileSize ? "10vw" : "2.9vw"}
                          height={isMobileSize ? "37.5px" : "2.5vh"}
                          fontSize={isMobileSize ? "14px" : "1.2vw"}
                          paddingLeft={"0"}
                          name={"myProfileTabEmailAddress"}
                          border={"0px solid #000000"}
                          value={quantity && quantity.qty}
                          textAlign={"center"}
                          setter={(value) =>
                            handleOnClickPlusMinusQty(null, quantity, value)
                          }
                        />
                      </div>
                      <div
                        className="itemDetailDescriptionQtyBtn"
                        onMouseOver={() =>
                          setQuantity({ ...quantity, onMouseOverPlus: true })
                        }
                        onMouseLeave={() =>
                          setQuantity({ ...quantity, onMouseOverPlus: false })
                        }
                        onClick={() =>
                          handleOnClickPlusMinusQty("plus", quantity)
                        }
                        style={
                          quantity &&
                            (quantity.qty || quantity.qty === 0) &&
                            quantity.stock &&
                            quantity.qty + 1 <= quantity.stock
                            ? {
                              color:
                                quantity && quantity.onMouseOverPlus
                                  ? "#000000"
                                  : "#d9d9d9",
                              cursor: "pointer",
                            }
                            : {
                              color:
                                quantity && quantity.onMouseOverPlus
                                  ? "#000000"
                                  : "#d9d9d9",
                              pointerEvents: "none",
                            }
                        }
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="itemDetailDescriptionQtyInStockContainer">
                    <div
                      className="itemDetailDescriptionQtyInStockBox"
                      style={{
                        backgroundColor:
                          quantity && quantity.stock > 0
                            ? quantity && quantity.stock < 5 ? "#ffa700" : "#08caa7"
                            : "#FE9292",
                      }}
                    ></div>
                    <div className="itemDetailDescriptionQtyInStockLabel">
                      {quantity && quantity.stock > 0
                        ? quantity && quantity.stock < 5 ? "Low stock" : "In stock"
                        : "Out of stock"}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {skeletonLoader ? (
              <>
                <div className="itemDetailDescriptionBtnAddToBag">
                  <div className="loading-btn w-full h-10 md:h-8 lg:h-12 bg-slate-300"></div>
                </div>
                <div className="itemDetailDescriptionBtnAddToWishlistSkeleton">
                  <div className="loading-btn w-full h-10 md:h-8 lg:h-12 bg-slate-300"></div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="itemDetailDescriptionBtnAddToBag"
                  style={{
                    backgroundColor:
                      quantity &&
                        quantity.qty !== 0 &&
                        ((itemDetails &&
                          itemDetails.combinations &&
                          itemDetails.combinations.length > 0 &&
                          selectedCombination.combinationId) ||
                          (!selectedCombination.combinationId &&
                            itemDetails.combinations.length <= 0))
                        ? "#000000"
                        : "#D9D9D9",
                  }}
                  onClick={() =>
                    quantity &&
                      quantity.qty !== 0 &&
                      ((itemDetails &&
                        itemDetails.combinations &&
                        itemDetails.combinations.length > 0 &&
                        selectedCombination.combinationId) ||
                        (!selectedCombination.combinationId &&
                          itemDetails.combinations.length <= 0))
                      ? handleClickAddToCart(quantity, itemDetails)
                      : null
                  }
                >
                  <div>Add to bag</div>
                </div>
                <div
                  className="itemDetailDescriptionBtnAddToWishList"
                  onClick={() => handleOnClickAddToWishlist(itemDetails.id)}
                >
                  <div>
                    <img
                      src={
                        isProductAlreadyInWishlist
                          ? AddedToWishlistIcon
                          : AddToWishListIcon
                      }
                      alt="add-to-wishlist-icon"
                    ></img>
                    <div>
                      {isProductAlreadyInWishlist
                        ? "Added to wishlist"
                        : "Add to wishlist"}
                    </div>
                  </div>
                </div>
              </>
            )}
            {arrItemDetailDescriptions &&
              Array.isArray(arrItemDetailDescriptions) &&
              arrItemDetailDescriptions.length > 0 ? (
              <div className="itemDetailDescriptionDetails">
                {arrItemDetailDescriptions.map((detailDescription, index) => {
                  const { title, description, isOpenDetailDescription } =
                    detailDescription || {};
                  const descList = description && description.split('\n') || [];
                  return (
                    <div key={index} style={{ paddingBottom: "1em" }}>
                      <div className="eachDetailDescription" onClick={() => handleOnClickArrowIcon(title)}>
                        <div className="eachDetailDescriptionArrowIcon">
                          <img
                            src={
                              isOpenDetailDescription
                                ? ArrowDownIcon
                                : ArrowUpIcon
                            }
                            alt={
                              isOpenDetailDescription
                                ? "arrow-down-icon"
                                : "arrow-up-icon"
                            }
                            width={17}
                            height={10}
                          ></img>
                        </div>
                        <div className="eachDetailDescriptionLabel">
                          {title}
                        </div>
                      </div>
                      {isOpenDetailDescription ? (
                        <div className="eachDetailContainer">
                          <div className="eachDetailLabel">
                          {
                            descList && Array.isArray(descList) && descList.length > 0 && descList.map((desc) => (
                              <p>{desc}</p>
                            ))
                          }
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {skeletonLoader ? (
              <div className="itemDetailDescriptionProductCode">
                <div className="loading-btn w-32 w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 bg-slate-300 rounded-xl"></div>
              </div>
            ) : !skeletonLoader && itemDetails && itemDetails.sku ? (
              <div className="itemDetailDescriptionProductCode">
                {`Product code ${itemDetails.sku}`}
              </div>
            ) : null}
          </div>
        </div>
        <div className="itemDetailYouMayAlsoLikeContainer">
          <Scroller
            contents={itemDetails && itemDetails.youMayAlsoLike}
            imageContent={true}
            isMobileSize={isMobileSize}
            skeletonLoader={skeletonLoader}
            searchState={searchState}
            navigate={navigate}
          />
        </div>
      </div>
      <div className="itemDetailBottomSectionContainer">
        <div className="itemDetailBottomSectionContent">
          <div className="offerContainer">
            <div className="offerLabel">Enjoy 20% off on your first order</div>
            <div className="offerDescription">
              Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum
              dolor sit amet Lorem ipsum dolor sit amet.
            </div>
          </div>
          <div className="submitEmailContainer">
            <div className="submitEmailLabel">Lorem ipsum dolor sit amet</div>
            <div className="submitEmailInputAndSubmitBtnContainer">
              <InputText
                placeholder={"youremail@address.com"}
                width={isMobileSize ? "67%" : "20vw"}
                border={"1px solid #9E9E9E"}
                name={"emailUser"}
                fontSize={isMobileSize ? "12px" : "0.9vw"}
                height={isMobileSize ? "30px" : ""}
              />
              <div className="btnSubmitEmailUser">
                <div>Submit</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenViewAllSize ? (
        <FullscreenView
          setterOpenFullscreen={setIsOpenViewAllSize}
          flagger={"item details"}
        />
      ) : null}
    </div>
  );
};

export default ItemDetails;
