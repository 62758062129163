import React from "react";

const ReturnPolicy = () => {
  return (
    <section className="main-container">
      <div className="max-w-main py-8 space-y-4 sm:space-y-8">
        <div className="space-y-2 sm:space-y-4">
          <h1 className="text-2xl lg:text-4xl leading-normal font-bold">Returns & Exchanges Policy</h1>
          <p className="text-sm sm:text-base">Updated on 24 October 2023</p>
        </div>
        <div className="grid sm:grid-cols-2">
          <div className="space-y-2 sm:space-y-4 mb-4">
            <p className="text-xl leading-normal font-bold">BRING TO A STORE</p>
            <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
              <ul className="list-disc">
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Go to any register for assistance.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Refunds can be expected in 3-5 business days.</li>
              </ul>
            </div>
          </div>
          <div className="space-y-2 sm:space-y-4">
            <p className="text-xl leading-normal font-bold">SEND BY MAIL</p>
            <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
              <ul className="list-disc">
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Start your return online.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Ship your items with the prepaid shipping label that we'll email to you to print at home.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">No printer? Show your QR code at an eligible USPS or FedEx location and they'll print a shipping label for you.</li>
                <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Returns are processed in 8-12 business days and refunds can be expected 3-5 business days after processing.</li>
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="space-y-2 sm:space-y-4">
          <p className="text-xl leading-normal font-bold">Our Return Policy</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>We handle returns on a case-by-case basis with the ultimate goal of making our customers happy. We stand behind our goods and services, and want customers to be satisfied with them. We'll always do our best to take care of customers—our philosophy is to deal with them fairly and reasonably. We have long believed that when we treat our customers fairly, they in turn are fair with us. We apply refunds to the tender with which returned items were purchased. If we choose to provide a refund and no record of sale is available, we'll ask for personal identification and a refund will be provided at the current price on a Nordstrom Gift Card.</p>
            <p><span className="font-semibold">Need to make a return?</span> We no longer send return labels with orders, but you can still print your own prepaid label, get a QR code to use at eligible USPS or FedEx locations or make your return in store. Please be sure to pair the item with the correct package and label. Items sent to us by mistake, with the wrong label, or that do not meet our return criteria won't be returned to you or refunded.</p>
            <p><span className="font-semibold">We now accept eligible returns of Nordstrom and Nordstrom.com merchandise at Nordstrom Rack locations.</span> Fine jewelry, designer and Nordstrom Marketplace items purchased at Nordstrom or Nordstrom.com are not eligible to be returned to Nordstrom Rack stores. Boutique returns must be processed at the store where they were originally purchased.</p>
          </div>
        </div>
        <hr />
        <div className="space-y-2 sm:space-y-4">
          <p className="text-xl leading-normal font-bold">Frequently Asked Questions</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p><a href="#anchor-link-exchange" className="underline">How do I exchange an item?</a></p>
            <p><a href="#anchor-link-refunds-credited" className="underline">How are my refunds credited?</a></p>
            <p><a href="#anchor-link-international-returns" className="underline">How do I make an international return outside of the U.S.?</a></p>
            <p><a href="#anchor-link-nordstrom-com" className="underline">What about returns for purchases at Nordstrom.com?</a></p>
            <p><a href="#anchor-link-receipt" className="underline">Do I need a receipt from my purchase?</a></p>
            <p><a href="#anchor-link-hazardous-materials-return" className="underline">How do I return items containing potentially hazardous materials?</a></p>
            <p><a href="#anchor-link-all" className="underline">Do you take everything back?</a></p>
            <p><a href="#anchor-link-time-limit" className="underline">Is there a time limit? What if the item is worn?</a></p>
            <p><a href="#anchor-link-special-occasion" className="underline">Why do special-occasion dresses and some designer items have tags that say if the tag is removed the garment cannot be returned?</a></p>
            <p><a href="#anchor-link-restocking-fee" className="underline">Are there restocking fees?</a></p>
            <p><a href="#anchor-link-personal-identification" className="underline">Why does Nordstrom request my personal identification for a return without a record of sale?</a></p>
            <p><a href="#anchor-link-checks" className="underline">Do you take checks?</a></p>
            <p><a href="#anchor-link-paypal" className="underline">How do you process my return if I paid with PayPal (U.S.)?</a></p>
            <p><a href="#anchor-link-marketplace-return-account" className="underline">How long does it take for a Nordstrom Marketplace return to show back on my account?</a></p>
            <p><a href="#anchor-link-marketplace-return-by-date" className="underline">How is my Nordstrom Marketplace return-by date calculated?</a></p>
            <p><a href="#anchor-link-marketplace-return-refund" className="underline">Why didn't I get a refund on my Nordstrom Marketplace return?</a></p>
          </div>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-exchange" className="text-lg leading-normal font-bold">How do I exchange an item?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>For the best service and selection, please order your replacement items online or call 1.888.282.6060 for assistance.</p>
            <p>Please call customer service in the U.S. at 1.888.282.6060 for assistance returning oversized items, perishable goods or other unusual merchandise.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-refunds-credited" className="text-lg leading-normal font-bold">How are my refunds credited?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>All refunds with a sales receipt will be credited to the purchaser's original form of payment, including gift cards (so be sure to keep your gift cards, even if they have a $0 balance). We offer gift receipts to customers when they purchase gifts. When returning an item with a gift receipt, the refund may be credited to either the original form of payment or a Nordstrom gift card. If we choose to provide a refund and no record of sale is available, we will ask for personal identification and a return will be provided at current price on a Nordstrom gift card. (Gift cards cannot be redeemed for cash unless required by law.)</p>
            <ul className="list-disc">
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Items Returned by Mail: If you ordered by credit card, we'll credit your account within 5-7 business days of receipt. This will show on your next statement, depending on the issuing bank and/or billing cycle. If you have requested an exchange, your account will be charged for the new item and credited for the return.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">If you paid by gift card(s), it is important to keep your original gift card (even with a $0 balance) as, generally, refunds or returns will be credited to your original gift card(s). If you also paid with a Nordstrom Note, we'll credit it back to your account within 72 hours. If you have questions, contact customer care at 1.888.282.6060.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">If PayPal, Afterpay or a credit card was used in combination with a gift card or Nordstrom Note, the merchandise amount will be returned proportionally to the tenders used.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Shipping Charges: Unfortunately, we cannot refund shipping charges.</li>
            </ul>
            <p><span className="font-semibold">I don't have a printer. Is there another way I can do a return?</span></p>
            <p>If you'd like to do a digital return but don't have a printer, please follow these steps.</p>
            <p>If you have a Nordstrom.com account:</p>
            <ul className="list-disc">
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Sign into your account and visit your Purchases page.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Choose <span className="font-semibold">Details</span> on the order containing the items to be returned.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Select <span className="font-semibold">Start a Return</span> and complete all steps in the online form.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Show your QR code at an eligible USPS or FedEx location and they'll print a shipping label for you.</li>
            </ul>
            <p>If you checked out as a guest:</p>
            <ul className="list-disc">
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"><span className="font-semibold">Start a free return</span>.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Enter your order number and ZIP code.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Complete all steps in the online form.</li>
              <li className="ml-4 sm:ml-6 pl-1 sm:pl-2">Show your QR code at an eligible USPS or FedEx location and they'll print a shipping label for you.</li>
            </ul>
            <p>Alternatively, you can take your return to your most convenient Nordstrom, Nordstrom Rack or Nordstrom Local.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">GIFT CARDS</p>
            <span>Gift cards can't be returned.</span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">BALSAM HILL</p>
            <span>Returns/exchanges are accepted within 30 days of purchase or prior to December 25 (whichever comes first). Please call Nordstrom customer care at 1.888.282.6060 to request a return shipping label. Nordstrom will not accept Balsam Hill products in stores or by mail.</span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">CASPER BED</p>
            <span>Please call 1.888.282.6060 for inquiries and assistance.</span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">FINE JEWELRY</p>
            <span>Please follow the instructions included in your shipment and fill out the return section of the packing slip when returning fine jewelry by mail. Return your package via the carrier of your choice, with return signature required. For your protection, you must insure the package for the value of the item enclosed. Fine jewelry can also be returned to Nordstrom stores that have a Fine Jewelry department. Simply bring the packing slip and/or credit card used when you placed the order.</span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">GREEN SHIPPING</p>
            <span>In our continued effort to minimize waste and lessen our impact on the environment, please note that your order may arrive in a slightly used shipping box.</span>
          </div>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p className="text-lg leading-normal font-bold">NORDSTROM RACK RETURN POLICY</p>
            <span>Please visit the Nordstrom Rack site for information about their return policy.</span>
          </div>
          <p>Questions? Call 1.888.282.6060.</p>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-international-returns" className="text-lg leading-normal font-bold">How are my refunds credited?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>International Nordstrom.com orders made with Borderfree have a different return process and do not qualify for free returns or merchandise exchanges. For a refund that includes duties, taxes and tariffs, please contact Nordstrom customer service and ask them to send you a Return Merchandise Authorization form on behalf of Borderfree. Then mail the completed form with your item(s) to Borderfree. If you choose to return an international order directly to Nordstrom, you will receive a refund for the merchandise from Borderfree but not duties, taxes or tariffs. Learn more about international returns.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-nordstrom-com" className="text-lg leading-normal font-bold">What about returns for purchases at Nordstrom.com?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Simply send your item back or bring it to a store near you and we'll do our best to take care of you.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-receipt" className="text-lg leading-normal font-bold">Do I need a receipt from my purchase?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Your receipt or order number helps us locate your purchase the fastest. If you don't have a receipt, we should be able to find the purchase in other ways, but we may ask you for more information so we can better assist with your request. If no record of sale is available and we choose to provide a refund, your personal identification will be required to process the return and the return is provided at current price on a Nordstrom Gift Card.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-hazardous-materials-return" className="text-lg leading-normal font-bold">How do I return items containing potentially hazardous materials?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Please pack, label and ship these items separately, and remember to attach the special label we've included to each package. Due to shipping regulations, this is required by USPS.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-all" className="text-lg leading-normal font-bold">Do you take everything back?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>We'll do our best to take care of you, but from time to time we cannot accommodate a return.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-time-limit" className="text-lg leading-normal font-bold">Is there a time limit? What if the item is worn?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>We will do our best to take care of customers and deal with them fairly; we ask that our customers treat us fairly as well. From time to time we may not accept a return. There are no time limits for returns or exchanges.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-special-occasion" className="text-lg leading-normal font-bold">Why do special-occasion dresses and some designer items have tags that say if the tag is removed the garment cannot be returned?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>If customers need to return a special-occasion dress or designer item, we ask that the item be in the same condition as when they received it, with all packaging included and tags still attached. Customers have told us that it's important to them that the special-occasion dresses and designer items they're purchasing have not been worn or used prior to their purchase. Over the years, we have also found that we received a disproportionate number of returns that appeared to have been previously worn in these categories, compared to other categories we sell. To meet our customers' expectations, we implemented a tag or card to help ensure these items are returned in original condition. </p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-restocking-fee" className="text-lg leading-normal font-bold">Are there restocking fees?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>No.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-personal-identification" className="text-lg leading-normal font-bold">Why does Nordstrom request my personal identification for a return without a record of sale?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Because of our liberal return philosophy, we have this internal auditing procedure to give us the ability to monitor and investigate refunds and returns without a record of sale.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-checks" className="text-lg leading-normal font-bold">Do you take checks?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Checks will no longer be accepted as payment for merchandise. However, checks will continue to be accepted for payment on accounts.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-paypal" className="text-lg leading-normal font-bold">How do you process my return if I paid with PayPal (U.S.)?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>If the full amount of your purchase was made with PayPal, your refund will be credited to the same PayPal account. If your PayPal purchase included a Nordstrom Note or Nordstrom Gift Card, your refund will be credited to your PayPal account in separate amounts. If your return is made in store, you'll receive your refund in the form of a Nordstrom Gift Card.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-marketplace-return-account" className="text-lg leading-normal font-bold">How long does it take for a Nordstrom Marketplace return to show back on my account?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Your refund status can be found in your purchase history. Please allow 10–14 business days for us to process your return. You'll receive an email confirmation when your return has been processed and when we begin issuing your refund.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-marketplace-return-by-date" className="text-lg leading-normal font-bold">How is my Nordstrom Marketplace return-by date calculated?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Purchases made from a Nordstrom Marketplace seller begin once the order has shipped. Each seller has a specific return window with a designated date assigned; you can find it in your order history.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
        <div className="space-y-2 sm:space-y-4">
          <p id="anchor-link-marketplace-return-refund" className="text-lg leading-normal font-bold">Why didn't I get a refund on my Nordstrom Marketplace return?</p>
          <div className="space-y-2 sm:space-y-4 text-sm sm:text-base">
            <p>Nordstrom Marketplace returns are eligible for a full refund by their given return-by date. Return dates can be found in your purchase history. Returned items must be in the same condition as when they were received: unworn, undamaged, unaltered and with the original tags. Returns that don't meet this policy won't be accepted.</p>
          </div>
          <p className="underline" style={{cursor: 'pointer'}} onClick={() => {window.scrollTo(0, 0)}}>Back to top</p>
        </div>
      </div>
    </section>
  )
}

export default ReturnPolicy;