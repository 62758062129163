import React, { useEffect, useState } from "react";

// Components
import {
  InputText,
  InputTextArea,
  InputDropdown,
  InputDropdownAsync,
  PhoneNumberInput,
} from "../../../components/Inputs";

import GoogleMaps from "../../../components/GoogleMaps";

// Core ui icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

// Helpers
import { createCountriesOptions } from "../../../helpers/getCountries";
import { timer } from "../../../helpers/timer";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";

// Apis
import {
  createNewShippingAddress,
  getUserShippingAddresses,
  getUserShippingAddressById,
  editShippingAddress,
  deleteShippingAddress
} from "../../../api/shippingAddresses";
import {
  getAllCountries,
  getCities,
  getProvinces,
  getSubDistricts,
} from "../../../api/countries";

// Images and Icons
import EmptyWishlistIcon from "../../../assets/myProfileIcons/EmptyWishlistIcon.png";
import DeleteProductIcon from "../../../assets/itemImagesAndIcons/DeleteProductIcon.png";
import BackToPreviousPageIcon from "../../../assets/myProfileIcons/BackToPreviousPageIcon.png"

const MyAddressTab = ({ userDetail, isMobileSize, dispatch }) => {
  const [isOpenCreateNewAddressForm, setIsOpenCreateNewAddressForm] = useState(false);
  const [isOpenEditAddressForm, setIsOpenEditAddressForm] = useState(false)
  const [addresses, setAddresses] = useState([]);
  const [newShippingAddressState, setNewShippingAddressState] = useState({
    firstName: "",
    lastName: "",
    addressDetails: "",
    addressName: "",
    cityId: "",
    provinceId: "",
    subDistrictId: "",
    postalCode: "",
    number: "",
    icon: icon.cifId,
    phone_code: "+62",
    country_id: 104,
    city_label: "",
    province_label: "",
    subdistrict_label: "",
    isOpenDropdownCountry: false,

  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [subDistrictOptions, setSubDistrictOptions] = useState([]);

  const [provincedValue, setProvincedValue] = useState(null)
  const [cityValue, setCityValue] = useState(null)
  const [subDistrictValue, setSubDistrictValue] = useState(null)

  const [propAddressId, setPropAddressId] = useState(null)

  const [propAddressNameForDelete, setPropAddressNameForDelete] = useState("")

  // Gmaps state
  const [latLng, setLatLng] = useState({ lat: -6.1753917, lng: 106.8271533 }) // Pinpoint Location DKI Jakarta
  const [mLatLng, setMLatLng] = useState({ mLat: -6.1753917, mLng: 106.8271533 })  // Pinpoint Location DKI Jakarta
  const [locationDetails, setLocationDetails] = useState('')

  // Validation state
  const [invalidProvince, setInvalidProvince] = useState(false);
  const [invalidCity, setInvalidCity] = useState(false);
  const [invalidSubDistrict, setInvalidSubDistrict] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidAddressDetails, setInvalidAddressDetails] = useState(false);
  const [invalidStreetAddress, setInvalidStreetAddress] = useState(false);
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidCountry, setInvalidCountry] = useState(false);
  const [invalidAddressName, setInvalidAddressName] = useState(false);

  const [openDeleteAddressModal, setOpenDeleteAddressModal] = useState(false)
  const [openConfirmCancelAddressModal, setOpenConfirmCancelAddressModal] = useState(false)

  const fetchAllCountries = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const response = await getAllCountries();
    if (response && response.data) {
      const { data } = response;
      if (data && Array.isArray(data) && data.length > 0) {
        setCountryOptions(createCountriesOptions(data));
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchProvinces = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getProvinces();
    if (result && result.data) {
      let finalProvinceOptions = result.data;
      finalProvinceOptions = finalProvinceOptions.map((prov) => {
        let objProv = {
          label: prov.province,
          value: prov.province_id,
        };
        return objProv;
      });
      setProvinceOptions(finalProvinceOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchCities = async (provinceId) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getCities(provinceId);
    if (result && result.data) {
      let finalCityOptions = result.data;
      finalCityOptions = finalCityOptions.map((city) => {
        let objCity = {
          label: city.city_name,
          value: {
            id: city.city_id,
            postal_code: city.postal_code,
          },
        };
        return objCity;
      });
      setCityOptions(finalCityOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const fetchSubDistricts = async (cityId) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getSubDistricts(cityId);
    if (result && result.data) {
      let finalSubDistrictOptions = result.data;
      finalSubDistrictOptions = finalSubDistrictOptions.map((subDistrict) => {
        let objSubDistrict = {
          label: subDistrict.subdistrict_name,
          value: subDistrict.subdistrict_id,
        };
        return objSubDistrict;
      });
      setSubDistrictOptions(finalSubDistrictOptions);
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleOnChangePhoneNumber = (
    value,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    let parts = value.split(" ");
    let withoutCountryCode;

    if (phoneNumberState && phoneNumberState.phone_code) {
      withoutCountryCode = parts[1];
    } else {
      withoutCountryCode = parts.join(" ");
    }

    setterPhoneNumber({
      ...phoneNumberState,
      number: withoutCountryCode,
    });
  };

  const handleChangePhoneNumberDropdown = (
    val,
    phoneNumberState,
    setterPhoneNumber
  ) => {
    const { value: { country_code, phone_code, id } = {} } = val || {};
    let countryIcon = "";
    if (country_code) {
      countryIcon = icon[`cif${country_code}`];
    }
    setterPhoneNumber({
      ...phoneNumberState,
      icon: countryIcon,
      phone_code: phone_code,
      country_id: id,
      isOpenDropdownCountry: false,
    });
  };

  const handleOnChangeDropdownProvinceCityAndSubDistrict = (value, flagger) => {
    if (flagger) {
      if (flagger === "province") {
        setNewShippingAddressState({
          ...newShippingAddressState,
          province_label: value.label,
          provinceId: value.value,
        });
        setCityOptions([]);
        fetchCities(value.value);
        setProvincedValue(value)
      }
      if (flagger === "city") {
        setNewShippingAddressState({
          ...newShippingAddressState,
          city_label: value.label,
          cityId: value.value.id,
          postalCode: value.value.postal_code,
        });
        setSubDistrictOptions([]);
        fetchSubDistricts(value.value.id);
        setCityValue(value)
      }
      if (flagger === "sub-district") {
        setNewShippingAddressState({
          ...newShippingAddressState,
          subdistrict_label: value.label,
          subDistrictId: value.value,
        });
        setSubDistrictValue(value)
      }
    }
  };

  const handleOnCancelCreateNewAddress = () => {
    if (isOpenCreateNewAddressForm) {
      setIsOpenCreateNewAddressForm(false);
    }

    if (isOpenEditAddressForm) {
      setIsOpenEditAddressForm(false)
    }

    setLatLng({ lat: -6.1753917, lng: 106.8271533 })
    setMLatLng({ mLat: -6.1753917, mLng: 106.8271533 })
    setLocationDetails('')

    setNewShippingAddressState({
      firstName: "",
      lastName: "",
      addressDetails: "",
      addressName: "",
      cityId: "",
      provinceId: "",
      subDistrictId: "",
      postalCode: "",
      number: "",
      icon: "",
      phone_code: "",
      country_id: "",
      city_label: "",
      province_label: "",
      subdistrict_label: "",
      isOpenDropdownCountry: false,
    })

    setProvincedValue(null)
    setCityValue(null)
    setSubDistrictValue(null)

    setInvalidProvince(false);
    setInvalidCity(false);
    setInvalidSubDistrict(false);
    setInvalidPhoneNumber(false);
    setInvalidAddressDetails(false);
    setInvalidStreetAddress(false);
    setInvalidPostalCode(false);
    setInvalidFirstName(false);
    setInvalidLastName(false);
    setInvalidCountry(false);
    setInvalidAddressName(false)

    setOpenConfirmCancelAddressModal(false)
  };

  const handleValidationSaveNewAddress = () => {
    const {
      provinceId,
      cityId,
      subDistrictId,
      number,
      addressDetails,
      postalCode,
      firstName,
      lastName,
      country_id,
      addressName,
    } = newShippingAddressState;

    let isValidProvince = false;
    let isValidCity = false;
    let isValidSubDistrict = false;
    let isValidPhoneNumber = false;
    let isValidAddressDetails = false;
    let isValidStreetAddress = false;
    let isValidPostalCode = false;
    let isValidFirstName = false;
    let isValidLastName = false;
    let isValidCountry = false;
    let isValidAddressName = false;

    if (!provinceId) {
      isValidProvince = true;
    }
    if (!cityId) {
      isValidCity = true;
    }
    if (!subDistrictId) {
      isValidSubDistrict = true;
    }
    if (!number) {
      isValidPhoneNumber = true;
    }
    if (!addressDetails) {
      isValidAddressDetails = true;
    }
    if (!locationDetails) {
      isValidStreetAddress = true;
    }
    if (!postalCode) {
      isValidPostalCode = true;
    }
    if (!firstName) {
      isValidFirstName = true;
    }
    if (!lastName) {
      isValidLastName = true;
    }
    if (!country_id) {
      isValidCountry = true;
    }
    if (!addressName) {
      isValidAddressName = true;
    }

    setInvalidProvince(isValidProvince);
    setInvalidCity(isValidCity);
    setInvalidSubDistrict(isValidSubDistrict);
    setInvalidPhoneNumber(isValidPhoneNumber);
    setInvalidAddressDetails(isValidAddressDetails);
    setInvalidStreetAddress(isValidStreetAddress);
    setInvalidPostalCode(isValidPostalCode);
    setInvalidFirstName(isValidFirstName);
    setInvalidLastName(isValidLastName);
    setInvalidCountry(isValidCountry);
    setInvalidAddressName(isValidAddressName);

    if (openConfirmCancelAddressModal) setOpenConfirmCancelAddressModal(false)

    if (
      isValidProvince !== true &&
      isValidCity !== true &&
      isValidSubDistrict !== true &&
      isValidPhoneNumber !== true &&
      isValidAddressDetails !== true &&
      isValidStreetAddress !== true &&
      isValidPostalCode !== true &&
      isValidFirstName !== true &&
      isValidLastName !== true &&
      isValidCountry !== true &&
      isValidAddressName !== true
    ) {
      handleOnSaveNewAddress();
    }
  };

  const handleOnSaveNewAddress = async () => {
    const body = {
      province_id: newShippingAddressState.provinceId,
      city_id: newShippingAddressState.cityId,
      subdistrict_id: newShippingAddressState.subDistrictId,
      phone_number: newShippingAddressState.number,
      address_details: newShippingAddressState.addressDetails,
      street_address: locationDetails,
      latitude_marker: mLatLng && mLatLng.mLat,
      longitude_marker: mLatLng && mLatLng.mLng,
      latitude_map: latLng && latLng.lat,
      longitude_map: latLng && latLng.lng,
      postal_code: newShippingAddressState.postalCode,
      first_name: newShippingAddressState.firstName,
      last_name: newShippingAddressState.lastName,
      country_id: newShippingAddressState.country_id,
      address_name: newShippingAddressState.addressName,
      city_label: newShippingAddressState.city_label,
      province_label: newShippingAddressState.province_label,
      subdistrict_label: newShippingAddressState.subdistrict_label,
    };
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });

    if (propAddressId) {
      body.address_id = propAddressId
      const result = await editShippingAddress(body);
      if (result?.data) {
        window.location.reload();
      }
    } else {
      const result = await createNewShippingAddress(body);
      if (result?.data) {
        window.location.reload();
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const handleFetchShippingAddresses = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await getUserShippingAddresses();
    if (result && result.data) {
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: true,
      });
      const { data } = result || {};
      if (data && Array.isArray(data) && data.length > 0) {
        setAddresses(data);
      }
      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
      fetchAllCountries();
      fetchProvinces();
    }
  };

  useEffect(() => {
    if (userDetail) {
      handleFetchShippingAddresses();
    }
  }, [userDetail]);

  const handleOpenEditForm = async (id) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });

    setPropAddressId(id)

    const result = await getUserShippingAddressById(id)
    if (result && result.data) {
      setIsOpenEditAddressForm(true)

      const { data } = result || {};
      const {
        address_details,
        address_name,
        city_id,
        country_id,
        first_name,
        last_name,
        phone_number,
        postal_code,
        province_id,
        subdistrict_id,
        city_label,
        province_label,
        subdistrict_label,
        street_address,
        latitude_marker,
        longitude_marker,
        latitude_map,
        longitude_map,
        country: {
          country_code,
          phone_code
        } = {}
      } = data || {};

      setLocationDetails(street_address)
      setLatLng({ lat: Number(latitude_map), lng: Number(longitude_map) })
      setMLatLng({ mLat: Number(latitude_marker), mLng: Number(longitude_marker) })

      setProvincedValue({ label: province_label, value: province_id })
      setCityValue({ label: city_label, value: city_id })
      setSubDistrictValue({ label: subdistrict_label, value: subdistrict_id })
      setNewShippingAddressState({
        firstName: first_name,
        lastName: last_name,
        addressDetails: address_details,
        addressName: address_name,
        cityId: city_id,
        provinceId: province_id,
        subDistrictId: subdistrict_id,
        postalCode: postal_code,
        number: phone_number,
        icon: icon[`cif${country_code}`],
        phone_code: phone_code,
        country_id: country_id,
        city_label,
        province_label,
        subdistrict_label,
        isOpenDropdownCountry: false,
      })

      const { data: cityArr = [] } = await getCities(province_id)
      let finalCityOptions = cityArr
      finalCityOptions = finalCityOptions && finalCityOptions.map((city) => {
        let objCity = {
          label: city.city_name,
          value: {
            id: city.city_id,
            postal_code: city.postal_code,
          },
        };
        return objCity;
      })
      setCityOptions(finalCityOptions);

      const { data: subDisctrictArr = [] } = await getSubDistricts(city_id)
      let finalSubDistrictOptions = subDisctrictArr;
      finalSubDistrictOptions = finalSubDistrictOptions.map((subDistrict) => {
        let objSubDistrict = {
          label: subDistrict.subdistrict_name,
          value: subDistrict.subdistrict_id,
        };
        return objSubDistrict;
      });
      setSubDistrictOptions(finalSubDistrictOptions);

      dispatch({
        type: "SET_IS_LOADING",
        isLoading: false,
      });
    }
  }

  const handleOnDeleteEachItem = async (id) => {
    const result = await deleteShippingAddress(id);
    if (result && !result.error) {
      handleFetchShippingAddresses()

      const beforeFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: true,
            msg: "Delete shipping address success",
            show: true,
          },
        });
      };
      const afterFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: false,
            msg: "",
            show: false,
          },
        });
      };
      timer(beforeFunction, afterFunction, 3000);
      setOpenDeleteAddressModal(false)
      setPropAddressNameForDelete("")
    }
  }

  const handleOpenDeleteAddressModal = async (addressName, addressId) => {
    setOpenDeleteAddressModal(true)
    setPropAddressNameForDelete(addressName)
    setPropAddressId(addressId)
  }

  const handleCancelDeleteAddressModal = async () => {
    setOpenDeleteAddressModal(false)
    setPropAddressNameForDelete("")
    setPropAddressId(null)
  }

  const handleBackToPreviousPage = () => {
    setIsOpenCreateNewAddressForm(false)
    setIsOpenEditAddressForm(false)
  }

  return (
    <div className="tabMainContainer">
      <div className="tabNameContainer">
        {!isMobileSize ? (
          <div className="tabNameStyle">
            {
              isOpenCreateNewAddressForm ? "Add new address" : isOpenEditAddressForm ? "Edit my address" : "My address"
            }
          </div>
        ) : null}
        {!isOpenCreateNewAddressForm && !isOpenEditAddressForm ? (
          <div
            className="btnAddNewAddress"
            onClick={() => setIsOpenCreateNewAddressForm(true)}
            style={isMobileSize ? { width: "40%" } : {}}
          >
            <div>ADD NEW ADDRESS</div>
          </div>
        ) : null}
      </div>
      {!isOpenCreateNewAddressForm && !isOpenEditAddressForm ? (
        <div className="allAddressesContainer">
          {addresses && Array.isArray(addresses) && addresses.length > 0 ? (
            addresses.map((address, index) => {
              const {
                id,
                address_name,
                phone_number,
                country,
                full_address,
                full_name
              } = address || {};
              return (
                <div key={index} className="eachAddressContainer">
                  <div className="eachMyOrderContentContainer">
                    <div className="eachAddressNameStyle">
                      {address_name ? capitalizeFirstLetter(address_name) : ""}
                    </div>
                    {full_name ? (
                      <div
                        className="eachStreetNameAndCountryDescStyle"
                        style={{ marginTop: "0.5vh" }}
                      >
                        {`${full_name}`}
                      </div>
                    ) : null}
                    {country?.phone_code || phone_number ? (
                      <div
                        className="eachStreetNameAndCountryDescStyle"
                        style={{ marginTop: "0.5vh" }}
                      >
                        {`${country?.phone_code ? country.phone_code : ""}-${phone_number}`}
                      </div>
                    ) : null}
                    <div className="eachStreetNameAndCountryDescStyle mb-2">
                      {full_address}
                    </div>
                    <div className="editDeleteContainer">
                      <div
                        className="btnEditAddress flex-initial w-64 px-2 py-1"
                        onClick={() => handleOpenEditForm(id)}
                        style={isMobileSize ? { width: "40%" } : {}}
                      >
                        <div>EDIT</div>
                      </div>
                      <div
                        onClick={() => handleOpenDeleteAddressModal(address_name, id)}
                      >
                        <img
                          src={DeleteProductIcon}
                          alt="delete-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="emptyWishlistContainer">
              <img src={EmptyWishlistIcon} alt="empty-wishlist-icon" />
              <div>No address yet.</div>
            </div>
          )}
        </div>
      ) : (
        <div className="allAddressesContainer">
          <div className="inputContainer">
            <div className="sm:hidden text-base sm:text-lg font-bold flex space-x-3 items-center mb-4">
              <button onClick={handleBackToPreviousPage}>
                <img className="h-4 w-4 sm:hidden object-contain" src={BackToPreviousPageIcon} alt="back" />
              </button>
              <p>{isOpenCreateNewAddressForm ? "Add new address" : isOpenEditAddressForm ? "Edit my address" : "My address"}</p>
            </div>
            <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
              <div className="inputLabel">First name</div>
              <InputText
                placeholder={"Fill in your first name"}
                width={"100%"}
                height={isMobileSize ? "37.5px" : "45px"}
                fontSize={isMobileSize ? "14px" : "16px"}
                name={"firstNameShippingAddress"}
                border={"1px solid #000000"}
                borderRadius={"5px"}
                value={newShippingAddressState.firstName}
                setter={(value) =>
                  setNewShippingAddressState({
                    ...newShippingAddressState,
                    firstName: value,
                  })
                }
              />
              {invalidFirstName && !newShippingAddressState.firstName ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
            <div
              style={{
                width: isMobileSize ? "100%" : "42.2%",
                paddingTop: isMobileSize ? "1em" : "0",
              }}
            >
              <div className="inputLabel">Last name</div>
              <InputText
                placeholder={"Fill in your last name"}
                width={"100%"}
                height={isMobileSize ? "37.5px" : "45px"}
                fontSize={isMobileSize ? "14px" : "16px"}
                name={"lastNameShippingAddress"}
                border={"1px solid #000000"}
                borderRadius={"5px"}
                value={newShippingAddressState.lastName}
                setter={(value) =>
                  setNewShippingAddressState({
                    ...newShippingAddressState,
                    lastName: value,
                  })
                }
              />
              {invalidLastName && !newShippingAddressState.lastName ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
          </div>
          <div className="mt-4">
            <div className="inputLabel">Address location</div>
            <GoogleMaps
              dispatch={dispatch}
              latLng={latLng}
              mLatLng={mLatLng}
              locationDetails={locationDetails}
              setLatLng={setLatLng}
              setMLatLng={setMLatLng}
              setLocationDetails={setLocationDetails}
              shippingAddressCheckout={false}
            />
          </div>
          <div
            className="inputContainer"
            style={isMobileSize ? { marginTop: "1em" } : {}}
          >
            <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
              <div className="inputLabel">Street address</div>
              <InputTextArea
                width={"100%"}
                border={"1px solid #000000"}
                borderRadius={"5px"}
                isMobileSize={isMobileSize}
                height={isMobileSize ? "61px" : "81px"}
                fontSize={isMobileSize ? "14px" : "16px"}
                // placeholder={"Fill in street address"}
                name={"shippingAddressStreetAddress"}
                value={locationDetails}
                disabled={true}
                // setter={(value) =>
                //   setNewShippingAddressState({
                //     ...newShippingAddressState,
                //     addressDetails: value,
                //   })
                // }
              />
              {invalidStreetAddress &&
                !locationDetails ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
            <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
              <div className="inputLabel">Address details</div>
              <InputTextArea
                width={"100%"}
                border={"1px solid #000000"}
                borderRadius={"5px"}
                isMobileSize={isMobileSize}
                height={isMobileSize ? "61px" : "81px"}
                fontSize={isMobileSize ? "14px" : "16px"}
                placeholder={"Fill in address details"}
                name={"shippingAddressAddressDetails"}
                value={newShippingAddressState.addressDetails}
                setter={(value) =>
                  setNewShippingAddressState({
                    ...newShippingAddressState,
                    addressDetails: value,
                  })
                }
              />
              {invalidAddressDetails &&
                !newShippingAddressState.addressDetails ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
          </div>
          <div
            style={{
              width: isMobileSize ? "100%" : "42.2%",
              paddingTop: isMobileSize ? "1em" : "0",
            }}
          >
            <div className="inputLabel">Save address as</div>
            <InputText
              placeholder={"Fill your address name"}
              width={"100%"}
              height={isMobileSize ? "37.5px" : "45px"}
              fontSize={isMobileSize ? "14px" : "16px"}
              name={"addressNameShippingAddress"}
              border={"1px solid #000000"}
              borderRadius={"5px"}
              value={newShippingAddressState.addressName}
              setter={(value) =>
                setNewShippingAddressState({
                  ...newShippingAddressState,
                  addressName: value,
                })
              }
            />
            {invalidAddressName && !newShippingAddressState.addressName ? (
              <div className="errorMessage">This field is required</div>
            ) : null}
          </div>
          <div
            className="inputContainer"
            style={{ marginTop: isMobileSize ? "0.5em" : "1.5em" }}
          >
            <div style={{ width: isMobileSize ? "100%" : "42.2%" }}>
              <div className="inputLabel">Province</div>
              <InputDropdown
                options={provinceOptions}
                placeholder={"Select Province..."}
                styles={{
                  border: "1px solid #000000",
                  borderRadius: "5px",
                  height: isMobileSize ? "37.5px" : "45px",
                  cursor: "pointer",
                  width: "100%",
                  fontSize: isMobileSize ? "14px" : "16px",
                }}
                functionSetter={(value) =>
                  handleOnChangeDropdownProvinceCityAndSubDistrict(
                    value,
                    "province"
                  )
                }
                value={provincedValue}
              />
              {invalidProvince && !newShippingAddressState.provinceId ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
            <div
              style={{
                width: isMobileSize ? "100%" : "42.2%",
                paddingTop: isMobileSize ? "1em" : "0",
              }}
            >
              <div className="inputLabel">Town/city</div>
              <InputDropdown
                options={cityOptions}
                placeholder={"Select City..."}
                styles={{
                  border: "1px solid #000000",
                  borderRadius: "5px",
                  height: isMobileSize ? "37.5px" : "45px",
                  cursor: "pointer",
                  width: "100%",
                  fontSize: isMobileSize ? "14px" : "16px",
                }}
                functionSetter={(value) =>
                  handleOnChangeDropdownProvinceCityAndSubDistrict(
                    value,
                    "city"
                  )
                }
                value={cityValue}
              />
              {invalidCity && !newShippingAddressState.cityId ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
          </div>
          <div
            className="inputContainer"
            style={{ marginTop: isMobileSize ? "0" : "1.5em" }}
          >
            <div
              style={{
                width: isMobileSize ? "100%" : "42.2%",
                paddingTop: isMobileSize ? "1em" : "0",
              }}
            >
              <div className="inputLabel">Sub district</div>
              <InputDropdown
                options={subDistrictOptions}
                placeholder={"Select Sub District..."}
                styles={{
                  border: "1px solid #000000",
                  borderRadius: "5px",
                  height: isMobileSize ? "37.5px" : "45px",
                  cursor: "pointer",
                  width: "100%",
                  fontSize: isMobileSize ? "14px" : "16px",
                }}
                functionSetter={(value) =>
                  handleOnChangeDropdownProvinceCityAndSubDistrict(
                    value,
                    "sub-district"
                  )
                }
                value={subDistrictValue}
              />
              {invalidSubDistrict && !newShippingAddressState.subDistrictId ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
            <div
              style={{
                width: isMobileSize ? "100%" : "42.2%",
                paddingTop: isMobileSize ? "1em" : "0",
              }}
            >
              <div className="inputLabel">Postal code</div>
              <InputText
                placeholder={"Fill in postal code"}
                width={"100%"}
                height={isMobileSize ? "35px" : "41px"}
                fontSize={isMobileSize ? "14px" : "16px"}
                name={"shippingAddressPostalCode"}
                border={"1px solid #000000"}
                borderRadius={"5px"}
                flex={1}
                value={newShippingAddressState.postalCode}
                setter={(value) =>
                  setNewShippingAddressState({
                    ...newShippingAddressState,
                    postalCode: value,
                  })
                }
              />
              {invalidPostalCode && !newShippingAddressState.postalCode ? (
                <div className="errorMessage">This field is required</div>
              ) : null}
            </div>
          </div>

          <div className="inputPhoneContainer">
            <div className="inputLabel">Phone</div>
            <PhoneNumberInput
              selectedCountry={newShippingAddressState}
              onChangeCountry={(value) => handleChangePhoneNumberDropdown(value, newShippingAddressState, setNewShippingAddressState)}
              phoneNumberValue={newShippingAddressState?.number}
              onChangePhoneNumber={(value) => setNewShippingAddressState(prev => ({ ...prev, number: value }))}
              countryOptions={countryOptions}
              isMobileSize={isMobileSize}
              disabled={false}
            />
            {(invalidCountry && !newShippingAddressState.country_id) ||
              (invalidPhoneNumber && !newShippingAddressState.number) ? (
              <div className="errorMessage">This field is required</div>
            ) : null}
            <div className="inputContainer" style={{ marginTop: "0" }}>
              <div style={{ flex: 1 }}>
                {newShippingAddressState &&
                  newShippingAddressState.isOpenDropdownCountry ? (
                  <InputDropdownAsync
                    options={countryOptions}
                    styles={{
                      marginTop: "1em",
                      border: "1px solid #000000",
                      borderRadius: "5px",
                      height: "45px",
                      cursor: "pointer",
                    }}
                    phoneNumberState={newShippingAddressState}
                    setterPhoneNumber={setNewShippingAddressState}
                    functionSetter={handleChangePhoneNumberDropdown}
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div
            className="cancelAndSaveBtnContainer"
            style={{ marginTop: isMobileSize ? "2em" : "5em" }}
          >
            <div className="cancelAndSaveBtnContentContainer">
              <div
                className="btnCancelEditProfile"
                onClick={() => setOpenConfirmCancelAddressModal(true)}
              >
                <div>Cancel</div>
              </div>
              <div
                className="btnSaveEditProfile"
                onClick={() => handleValidationSaveNewAddress()}
              >
                <div>Save</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        openDeleteAddressModal && (
          <div className="fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center">
            <div className="flex flex-col fixed bg-white z-50 sm:mb-4 md:mb-8 sm:mx-4 max-w-6xl max-h-[90vh] text-left py-4 sm:px-12 sm:py-6 space-y-4 sm:space-y-6">
              <p className="font-bold text-lg md:text-xl px-4 sm:px-0">
                Delete Address
              </p>
              <div className="flex-1 overflow-y-auto px-4 sm:px-0">
                <p>
                  Are you sure want to delete address "{propAddressNameForDelete}"? You can't restore a deleted address
                </p>
              </div>
              <div className="flex gap-4 max-w-xl mx-auto w-full px-4 sm:px-0 ">
                <button
                  className="flex-1 text-xs md:text-sm border border-maplexxon-blue p-2 text-maplexxon-blue"
                  onClick={() => handleCancelDeleteAddressModal()}
                >
                  Cancel
                </button>
                <button
                  className="flex-1 text-xs md:text-sm bg-maplexxon-blue text-white p-2"
                  onClick={() => handleOnDeleteEachItem(propAddressId)}
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="fixed top-0 bottom-0 right-0 left-0 z-40 bg-maplexxon-transparant-black" />
          </div>
        )
      }
      {
        openConfirmCancelAddressModal && (
          <div className="fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-center">
            <div className="flex flex-col fixed bg-white z-50 sm:mb-4 md:mb-8 sm:mx-4 max-w-6xl max-h-[90vh] text-left py-4 sm:px-12 sm:py-6 space-y-4 sm:space-y-6">
              <p className="font-bold text-lg md:text-xl px-4 sm:px-0">
                Confirmation cancel
              </p>
              <div className="flex-1 overflow-y-auto px-4 sm:px-0">
                <p>
                  Are you sure you want to cancel this process?
                </p>
              </div>
              <div className="flex gap-4 max-w-xl mx-auto w-full px-4 sm:px-0 ">
                <button
                  className="flex-1 text-xs md:text-sm border border-maplexxon-blue p-2 text-maplexxon-blue"
                  onClick={() => handleOnCancelCreateNewAddress()}
                >
                  Cancel
                </button>
                <button
                  className="flex-1 text-xs md:text-sm bg-maplexxon-blue text-white p-2"
                  onClick={() => handleValidationSaveNewAddress()}
                >
                  Save
                </button>
              </div>
            </div>
            <div className="fixed top-0 bottom-0 right-0 left-0 z-40 bg-maplexxon-transparant-black" />
          </div>
        )
      }
    </div>
  );
};

export default MyAddressTab;
