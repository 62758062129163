import React, { useRef } from "react";

// Styles
import "../styles/scroller.css";

// Images and Icons
import UncheckedSizeConvertionIcon from "../assets/itemImagesAndIcons/UncheckedSizeConvertionIcon.png";
import CheckedSizeConvertionIcon from "../assets/itemImagesAndIcons/CheckedSizeConvertionIcon.png";
import ArrowRightIcon from "../assets/landingPageImagesAndIcons/ArrowRightIcon.png";
// Helpers
import { formatPrice } from "../helpers/format-price";

const Scroller = (props) => {
  const {
    imageContent = false,
    contents,
    isMobileSize,
    flagger,
    searchState,
    navigate,
    skeletonLoader,
  } = props;

  const galleryRef = useRef(null);
  const scroll = (scrollOffset) => {
    galleryRef.current.scrollLeft += scrollOffset;
  };

  if (imageContent) {
    if (flagger === "landing-page-items") {
      return (
        <div className="landingPageFeaturedPordutsScrollerContainer">
          {
            !isMobileSize && (
              <div className="headerAndScrollBtnlandingPageFeaturedPordutsContainer">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={ArrowRightIcon}
                    className="rotate-180"
                    alt="grey-arrow-left-icon"
                    width={9}
                    height={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => scroll(isMobileSize ? -200 : -1000)}
                  ></img>
                  <img
                    src={ArrowRightIcon}
                    alt="grey-arrow-right-icon"
                    width={9}
                    height={16}
                    style={{ marginLeft: "1em", cursor: "pointer" }}
                    onClick={() => scroll(isMobileSize ? 200 : 1000)}
                  ></img>
                </div>
              </div>
            )
          }
          <div className="landingPageFeaturedProducts">
            <div className="image-gallery" ref={galleryRef}>
              {contents && Array.isArray(contents) && contents.length > 0
                ? contents.map((content, index) => {
                  const {
                    id,
                    images,
                    name,
                    price,
                    product_discounts,
                    discounted_price,
                    brand,
                  } = content;
                  let img_url;
                  if (images && Array.isArray(images) && images.length > 0) {
                    img_url = images[0] && images[0].image_url;
                  }
                  const productDiscount =
                    product_discounts &&
                      Array.isArray(product_discounts) &&
                      product_discounts.length > 0
                      ? product_discounts[0]
                      : null;
                  const salePrice = discounted_price;
                  return (
                    <div key={id} className="gallery-item" onClick={() => navigate(`/items/${id}`)}>
                      <div
                        className="image-background"
                      >
                        <img
                          src={img_url}
                          alt={`Each-item-${index + 1}`}
                          style={{
                            objectFit: "contain",
                            borderRadius: isMobileSize ? "10px" : "25px",
                            height: "auto",
                            aspectRatio: "1/1"
                          }}
                          className="border-2 border-maplexxon-grey border-solid"
                        />
                      </div>
                      <div
                        className={
                          isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                        }
                        style={{
                          marginLeft: isMobileSize ? "0" : "0.5vw",
                        }}
                      >
                        <div className="text-xs font-bold text-[#006787] mb-1">{brand?.name}</div>
                        <div className="font-semibold capitalize">{name}</div>
                        <div className="itemPriceContainer">
                          <div>
                            {`${(searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code) ||
                              ""
                              }`}
                          </div>
                          <div
                            className={(productDiscount?.value_discount || productDiscount?.percentage_discount) ? "line-through" : ""}
                          >
                            {formatPrice(price)}
                          </div>
                          {/* {productDiscount &&
                            productDiscount.percentage_discount &&
                            productDiscount.percentage_discount !== 0 ? (
                              <div
                                style={{ marginLeft: "0.75vw" }}
                              >{`${productDiscount.percentage_discount}% OFF`}</div>
                            ) : null} */}
                        </div>
                        {salePrice ? (
                          <div className="salePriceStyle">
                            {`${(searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code) ||
                              ""
                              } ${formatPrice(salePrice)}`}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })
                : null}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {skeletonLoader ? (
            <div className="headerAndScrollBtn">
              <div className="loading-btn w-40 md:w-60 lg:w-80 h-6 md:h-10 lg:h-12 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="loading-btn w-10 md:w-12 lg:w-12 h-6 md:h-10 lg:h-12 animate-pulse bg-slate-300 rounded-xl"></div>
            </div>
          ) : (
            <div className="headerAndScrollBtn">
              <div>YOU MAY ALSO LIKE</div>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={ArrowRightIcon}
                    className="rotate-180"
                    alt="grey-arrow-left-icon"
                    width={9}
                    height={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => scroll(isMobileSize ? -200 : -1000)}
                  ></img>
                  <img
                    src={ArrowRightIcon}
                    alt="grey-arrow-right-icon"
                    width={9}
                    height={16}
                    style={{ marginLeft: "1em", cursor: "pointer" }}
                    onClick={() => scroll(isMobileSize ? 200 : 1000)}
                  ></img>
                </div>
              </div>
            </div>
          )}
          <div className="image-gallery-container">
            <div className="image-gallery" ref={galleryRef}>
              {skeletonLoader ? (
                <>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                  <div className="gallery-item">
                    <div className="image-background">
                      <div className="loading-btn image-background-image animate-pulse bg-slate-300 rounded-3xl"></div>
                    </div>
                    <div
                      className={
                        isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                      }
                    >
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                      <div className="pt-1 pb-1"></div>
                      <div className="loading-btn w-48 md:w-40 lg:w-60 h-4 md:h-3 lg:h-5 animate-pulse bg-slate-300 rounded-xl"></div>
                    </div>
                  </div>
                </>
              ) : !skeletonLoader &&
                contents &&
                Array.isArray(contents) &&
                contents.length > 0 ? (
                contents.map((content, index) => {
                  const { id, ymal_currency, images, name, price, brand, product_discounts, discounted_price } = content;
                  let img_url;
                  if (images && Array.isArray(images) && images.length > 0) {
                    img_url = images[0] && images[0].image_url;
                  }
                  const productDiscount =
                  product_discounts &&
                    Array.isArray(product_discounts) &&
                    product_discounts.length > 0
                    ? product_discounts[0]
                    : null;
                    const salePrice = discounted_price;
                  return (
                    <div key={index} className="gallery-item" onClick={() => navigate(`/items/${id}`)}>
                      <div className="image-background">
                        <img
                          src={img_url}
                          alt={`Each-item-${index + 1}`}
                          style={{
                            objectFit: "contain",
                            borderRadius: isMobileSize ? "10px" : "25px",
                            height: "auto",
                            aspectRatio: "1/1",
                          }}
                          className="border-2 border-maplexxon-grey border-solid"
                        />
                      </div>
                      <div
                        className={
                          isMobileSize ? "eachItemDescMobile" : "eachItemDesc"
                        }
                        style={{
                          marginLeft: isMobileSize ? "0" : "0.5vw",
                        }}
                      >
                        <div className="text-xs font-bold text-[#006787] mb-1">{brand?.name}</div>
                        <div className="font-semibold capitalize">{name}</div>
                        <div className="itemPriceContainer">
                          <div>
                            {`${(searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code) ||
                              ""
                              }`}
                          </div>
                          <div
                            className={(productDiscount?.value_discount || productDiscount?.percentage_discount) ? "line-through" : ""}
                          >
                            {formatPrice(price)}
                          </div>
                        </div>
                        {salePrice ? (
                          <div className="salePriceStyle">
                            {`${(searchState &&
                              searchState.currencyVal &&
                              searchState.currencyVal.code) ||
                              ""
                              } ${formatPrice(salePrice)}`}
                          </div>
                        ) : null}
                        {/* <div>{`${ymal_currency ? ymal_currency : "IDR"
                          } ${formatPrice(price)}`}</div> */}
                      </div>
                    </div>
                  );
                })
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="image-gallery-container">
          <div className="image-gallery" ref={galleryRef}>
            {contents &&
              Array.isArray(contents) &&
              contents.length > 0 &&
              contents.map((content, index) => {
                const {
                  sizeTitle,
                  sizeEquivalentToSize,
                  sizeConversions,
                  checked,
                } = content;
                return (
                  <div
                    key={index}
                    className="gallery-item"
                    style={{
                      border: "1px solid #D9D9D9",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="galeryItemFlex">
                      <img
                        src={
                          checked
                            ? CheckedSizeConvertionIcon
                            : UncheckedSizeConvertionIcon
                        }
                        width={isMobileSize ? 14 : 16}
                        height={isMobileSize ? 14 : 16}
                        alt="checkbox-icon"
                      />
                      <div className="sizeValueContentContainer">
                        <div className="sizeTitle">{sizeTitle}</div>
                        <div className="sizeEquivalentToSizeContainer">
                          <div style={{ fontWeight: "400" }}>
                            Equivalent to size
                          </div>
                          <div
                            style={{
                              fontWeight: isMobileSize ? "600" : "bold",
                            }}
                          >
                            {sizeEquivalentToSize}
                          </div>
                        </div>
                        <div className="sizeConversionsValueContainer">
                          {sizeConversions &&
                            Array.isArray(sizeConversions) &&
                            sizeConversions.length > 0 &&
                            sizeConversions.map((sizeConversion, index) => {
                              const { country, value } = sizeConversion;
                              return (
                                <div
                                  key={index}
                                  className="eachSizeConversionContainer"
                                >
                                  <div style={{ width: "20px" }}>{country}</div>
                                  <div>{value}</div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                    {/* <img src={ymal_img_url} alt={`Each-item-${index + 1}`} />
                    <div className="eachItemDesc">
                      <div>{ymal_name}</div>
                      <div>{`${ymal_currency} ${ymal_price}`}</div>
                    </div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
};

export default Scroller;
