import Axios from "../../src/helpers/axios";

export const registerUser = (body) => {
  return Axios.post("/api/sso/v1/users/create", body);
};

export const loginUser = (body) => {
  return Axios.post("/api/sso/v1/users/login", body);
};

export const requestForgotPassword = (email) => {
  return Axios.get(`/api/sso/v1/users/request/reset/password/${email}`);
};

export const resetPasswordUser = (body) => {
  return Axios.post("/api/sso/v1/users/change/password", body);
};

export const logoutUser = () => {
  return Axios.post("/api/sso/v1/users/logout");
};

export const updateUserProfile = (body) => {
  return Axios.put("/api/sso/v1/users/update/profile", body);
};

export const getGuestToken = () => {
  return Axios.get("/api/sso/v1/users/token/guest");
};

export const verifyUserAccount = (token) => {
  return Axios.post(`/api/sso/v1/users/verify/${token}`);
};

export const resendVerifyUserAccount = (email) => {
  return Axios.post(`/api/sso/v1/users/verify/resend/${email}`);
}

export const getUserProfile = () => {
  return Axios.get("/api/sso/v1/users/profile");
};

export const createUserProfile = (body) => {
  return Axios.post("/api/sso/v1/users/profile/create", body);
};

export const checkCurrentPassword = (body) => {
  return Axios.post("/api/sso/v1/users/check/current/password", body);
}