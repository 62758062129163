import React, { useState, useEffect } from 'react'
import Autocomplete from 'react-autocomplete'
import { APIProvider, Map, Marker, ControlPosition, MapControl } from '@vis.gl/react-google-maps';

// Components
import {
  InputTextArea,
} from "./Inputs";

// Apis
import {
  fetchAutoComplete,
  fetchPlaceDetails
} from '../api/googleMaps';

const GoogleMaps = ({
  dispatch,
  latLng,
  mLatLng,
  setLatLng,
  setMLatLng,
  setLocationDetails,
  shippingAddressCheckout
}) => {
  let autocompleteTimer

  const [locations, setLocations] = useState([])
  const [selectedLocationVal, setSelectedLocationVal] = useState('')
  const [zoom, setZoom] = useState(15)

  const handleFetchAutoComplete = async (query) => {
    try {
      // dispatch({
      //   type: "SET_IS_LOADING",
      //   isLoading: true,
      // });
      const response = await fetchAutoComplete(query)
      
      if (response && response.data) {
        setLocations(response.data && response.data.predictions)
      }
      // dispatch({
      //   type: "SET_IS_LOADING",
      //   isLoading: false,
      // });
    } catch (err) {
      console.log(err)
    }
  }

  const handleFetchPlaceDetails = async (placeId) => {
    try {
      // dispatch({
      //   type: "SET_IS_LOADING",
      //   isLoading: true,
      // });
      const response = await fetchPlaceDetails(placeId)
      if (response && response.data) {
        if (response?.data?.status == 'OK') {
          const result = response.data && response.data.result
          const geometry = result && result.geometry
          const lat = geometry && geometry.location && geometry.location.lat
          const lng = geometry && geometry.location && geometry.location.lng
          setLocationDetails(result && result.formatted_address)
          setLatLng({ lat, lng })
          setMLatLng({ mLat: lat, mLng: lng })
          setZoom(18)
        }
      }
      // dispatch({
      //   type: "SET_IS_LOADING",
      //   isLoading: false,
      // });
    } catch (err) {
      console.log(err)
    }
  }

  const handleAutocomplete = (e) => {
    const value = e && e.target && e.target.value
    setSelectedLocationVal(value)
    if (value && value.length > 2) {
      if (autocompleteTimer) {
        clearTimeout(autocompleteTimer)
      }
      autocompleteTimer = setTimeout(() => handleFetchAutoComplete(value), 1000)
    }
  }

  const handleSelectedItem = (value, item) => {
    setSelectedLocationVal(value)
    handleFetchPlaceDetails(item && item.place_id)
  }

  const handleMapChange = (e) => {
    const details = e.detail
    const lat = details && details.center && details.center.lat
    const lng = details && details.center && details.center.lng
    const zm = details && details.zoom

    setLatLng({ lat, lng })
    setZoom({ zoom: zm })
  }

  const handleMarkerDrag = (e) => {
    const details = e && JSON.parse(JSON.stringify(e))
    const lat = details.latLng && details.latLng.lat
    const lng = details.latLng && details.latLng.lng
    setLatLng({ lat, lng })
    setMLatLng({ mLat: lat, mLng: lng })
  }

  const handleMapClick = async (e) => {
    const { detail } = e
    const { placeId } = detail || {}

    await handleFetchPlaceDetails(placeId)
  }
  
  return (
    <div className="flex flex-col items-center justify-center my-4">
      <div className='outline outline-1 rounded-lg overflow-hidden'>
        <APIProvider apiKey={'AIzaSyBsDDGDk6wY513oK7aYQ7Q_EJtpeF-T9UQ'}>
          <Map
            className="w-[63vw] h-[500px]"
            onCameraChanged={handleMapChange}
            defaultCenter={{
              lat: latLng && latLng.lat || 0,
              lng: latLng && latLng.lng || 0
            }}
            center={{
              lat: latLng && latLng.lat || 0,
              lng: latLng && latLng.lng || 0
            }}
            defaultZoom={zoom}
            zoom={zoom}
            controlled={false}
            gestureHandling={'none'}
            disableDefaultUI={true}
            onClick={handleMapClick}
          >
            <Marker
              position={{
                lat: mLatLng.mLat || 0,
                lng: mLatLng.mLng || 0
              }}
              draggable
              onDragEnd={handleMarkerDrag}
            />
          </Map>
          <MapControl position={ControlPosition.TOP_CENTER}>
            <Autocomplete
              className="mb-4"
              getItemValue={(item) => item.description}
              items={locations}
              renderItem={(item, isHighlighted) =>
                <div key={item.place_id} className={`${isHighlighted ? "bg-gray-200" : "bg-white"} w-[35vw] text-base cursor-pointer`} style={{ fontFamily: '"Poppins", sans-serif' }}>
                  {item.description}
                </div>
              }
              renderInput={(props) =>
                <textarea rows={1} placeholder="Search your street address" className="w-[35vw] mt-6 text-base p-2 border border-solid border-black rounded-md" style={{ fontFamily: '"Poppins", sans-serif' }} {...props} />
              }
              value={selectedLocationVal}
              onChange={handleAutocomplete}
              onSelect={handleSelectedItem}
            />
          </MapControl>
        </APIProvider>
      </div>
      {/* <div className="text-center">
        <Autocomplete
          className="mb-4 z-10"
          getItemValue={(item) => item.description}
          items={locations}
          renderItem={(item, isHighlighted) =>
            <div key={item.place_id} className="w-[50vw] cursor-pointer" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
              {item.description}
            </div>
          }
          renderInput={(props) =>
            <textarea rows={1} placeholder="Search your street address" className="w-[50vw] p-2 border border-solid border-black rounded-md" {...props} />
          }
          value={selectedLocationVal}
          onChange={handleAutocomplete}
          onSelect={handleSelectedItem}
        />
      </div> */}
    </div>
  );
}

export default GoogleMaps;